<template>
	<div v-if="hasAccess">
		<slot />
	</div>

	<div v-else
		 class="missing-subscription">
		<div>
			<div class="image">
				<img src="https://www.generaxion.com/dk/wp-content/uploads/sites/4/2022/08/Generaxion_OPN_Logo_Hvid_vandret-05.png" />
			</div>

			<div class="content">
				<h1 v-text="resolvedTitle" />
				<div class="description" v-text="resolvedDescription" />

				<div class="from-to">
					<div class="from">OP'N Freemium</div>
					<div class="arrow">→</div>
					<div class="to">OP'N Plus</div>
				</div>

				<router-link :to="{ name: 'billing' }">
					<span v-if="!! icon"
						  class="icon"
						  v-text="icon"
					/>

					<span>{{ resolvedButton }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import feature from '@/mixins/billing/feature'

export default {
	mixins: [
		feature
	],

	props: {
		feature: {
			type: String,
			required: true
		},

		title: {
			type: String,
			required: false,
			default: null
		},

		description: {
			type: String,
			required: false,
			default: null
		},

		icon: {
			type: String,
			required: false,
			default: '🚀'
		},

		button: {
			type: String,
			required: false,
			default: null
		}
	},

	computed: {
		hasAccess() {
			return this.featuresHas(this.feature)
		},

		resolvedTitle() {
			return this.title || this.$t('billing.hasSubscription.title')
		},

		resolvedDescription() {
			return this.description || this.$t('billing.hasSubscription.description')
		},

		resolvedButton() {
			return this.button || this.$t('billing.hasSubscription.button')
		}
	}
}
</script>

<style lang="scss" scoped>
.missing-subscription {
	max-width: 600px;
	margin: 0 auto;

	> div {
		display: flex;
		flex-direction: column;

		box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
		border-radius: 8px;
		overflow: hidden;
		margin: 0 25px;
		background-color: #fff;

		> div.image {
			position: relative;
			width: 100%;
			padding-bottom: 35%;
			background: #367df7;

			> img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				height: 100%;
				max-width: 70%;
				max-height: 60%;
				object-fit: contain;
				object-position: center center;
				transform: translateX(-50%) translateY(-50%);
			}
		}

		> div.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 50px 35px;

			h1 {
				color: #000;
				font-size: 24px;
				letter-spacing: -.2px;
				font-weight: 800;
				text-align: center;
				margin: 0 0 14px 0;
			}

			div.description {
				color: #6c6c6c;
				font-size: 15px;
				font-weight: 400;
				text-align: center;
				-webkit-font-smoothing: antialiased;

				margin: 0;
				padding: 0;
			}

			div.from-to {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 20px;
				margin: 35px 0;

				div.from,
				div.to {
					color: #000;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: -.1px;
					text-decoration-thickness: .5px;
				}

				div.from {
					opacity: .5;
					text-decoration: line-through;
				}

				div.arrow {
					color: #000;
					font-size: 20px;
					font-weight: 300;
				}

				div.to {
					text-decoration: underline;

				}
			}

			a,
			button {
				display: inline-flex;
				align-items: center;
				gap: 15px;
				color: #fff;
				font-weight: 600;
				font-size: 14px;
				letter-spacing: -.1px;
				border: 0;
				box-shadow: none;
				outline: 0;
				border-radius: 6px;
				padding: 18px 26px;
				background-color: #000;

				span.icon {
					font-size: 125%;
				}
			}
		}
	}
}
</style>
