<template>
	<div v-else-if="element.slug === 'social.facebook'"
		 class="linkedin-post">
		<div class="user">
			<div class="image">
				<img src="/images/opn/logo_white.svg" />
			</div>

			<div class="meta">
				<div class="name">OP'N Co-pilot</div>
				<div class="time">44 minutes ago</div>
			</div>

			<div class="actions">
				<img src="/images/co-pilot/facebook-post/actions.png" />
			</div>
		</div>

		<div class="content">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.post"
			/>
		</div>

		<div class="image">
			<ai-image width="100%"
					  height="100%"
					  v-model="internalElement.images.image"
			/>
		</div>

		<div class="bottom">
			<div class="line1">
				<img src="/images/co-pilot/facebook-post/reactions.png" />
				<img src="/images/co-pilot/facebook-post/comments.png" />
			</div>

			<div class="line2">
				<img src="/images/co-pilot/facebook-post/like.png" />
				<img src="/images/co-pilot/facebook-post/comment.png" />
				<img src="/images/co-pilot/facebook-post/share.png" class="share" />
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.linkedin-post {
	max-width: 555px;
	margin: 0 auto;

	box-shadow: rgba(140, 140, 140, 0.2) 0px 0px 0px 1px;
	border-radius: 8px;

	.user {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px 15px 0 15px;

		.image {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
			background-color: #ee95d3;

			border: 1px solid rgba(0, 0, 0, .1);

			img {
				width: 60%;
				height: 60%;
				object-fit: cover;
			}
		}

		.meta {
			.name {
				color: #000;
				font-size: 15px;
				font-weight: 600;
			}

			.subline,
			.time {
				color: rgba(101, 103, 107, 1);
				font-size: 13px;
				font-weight: 400;
			}
		}

		.actions {
			margin-left: auto;

			img {
				height: 35px;
			}
		}
	}

	> .content {
		padding: 10px 15px;

		font-size: 15px;
		font-weight: 400;
	}

	> .image {
		position: relative;
		width: 100%;
		padding-bottom: 56%;

		> div {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.bottom {
		padding: 5px 10px;

		div.line1 {
			display: flex;
			align-items: center;
			justify-content: space-between;

			padding-bottom: 6px;

			border-bottom: 1px solid #ced0d4;

			img {
				height: 25px;
			}
		}

		div.line2 {
			display: flex;
			align-items: center;
			justify-content: space-around;

			padding-top: 6px;

			img {
				height: 25px;

				&.share {
					height: 22px;
				}
			}
		}
	}
}
</style>

<script>
import Editable from '@/app/copilot/components/Editable'
import AiImage from "@/app/copilot/components/AiImage";

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		iterate() {
			this.$emit('iterate')
		},

		onFocus() {
			this.$emit('focus')
		}
	},

	components: {
		AiImage,
		Editable
	}
}
</script>
