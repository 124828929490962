<template>
	<div class="midjourney-creative">
		<div class="top">
			<div class="intro">
				<div v-if="!! creative.style">
					<div class="style">{{ $t(`copilot.elements.content.editor.creativeModal.style.${creative.style}`) }}</div>
				</div>
				<div class="prompt">{{ creative.prompt }}</div>
			</div>

			<div>
				<button type="button"
						@click="onRemove"
						class="remove">
					<i class="fal fa-trash-o fa-fw" />
				</button>
			</div>
		</div>

		<div class="results">
			<template v-if="creative.midjourneyImageResults.length === 0">
				<div class="loader">
					<i class="fa fa-spinner fa-spin fa-fw" />

					<div v-if="creative.percentage > 0 && creative.percentage > percentage">{{ creative.percentage }}%</div>
					<div v-else-if="percentage !== null">{{ percentage }}%</div>
				</div>

				<div class="images">
					<div v-for="n in 4"
						 :key="`creative-${creative.id}-placeholder-${n}`"
						 class="placeholder">
						<div />
					</div>
				</div>
			</template>

			<template v-if="creative.midjourneyImageResults.length > 0">
				<div class="images">
					<div v-for="result in creative.midjourneyImageResults"
						 :key="`result-${result.id}`"
						 class="creative">
						<div>
							<div class="actions">
								<div @click="onAdd(result.path)">
									<i class="fa fa-plus fa-fw" />
								</div>

								<div @click="onDownload(result.path)">
									<i class="fa fa-download fa-fw" />
								</div>
							</div>

							<img :src="result.path" />
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

export default {
	props: {
		creative: {
			type: Object,
			required: true
		}
	},

	data: () => ({
		percentage: null,
		timeout: null,
	}),

	mounted() {
		this.calculatePercentage()
	},

	beforeDestroy() {
		clearTimeout(this.timeout)
	},

	methods: {
		calculatePercentage() {
			if (this.creative.path) {
				return
			}

			const target = 300

			const now = moment()

			const then = moment(this.creative.created.date)
			then.add(target, 'seconds')

			const seconds = then.diff(now, 'seconds')

			if (seconds <= 0) {
				this.percentage = 100
				return
			}

			this.percentage = Math.round((target - seconds) / target * 100)
			this.timeout = setTimeout(this.calculatePercentage, 1000)
		},

		onAdd(path) {
			this.$emit('add', path)
		},

		onRemove() {
			this.$emit('remove')
		},

		onDownload(path) {
			const link = document.createElement('a')
			link.download = 'image.png'
			link.href = path
			link.target = '_blank'

			document.body.appendChild(link)

			link.click()

			document.body.removeChild(link)
		}
	}
}
</script>

<style lang="scss" scoped>
@keyframes blink {
	0%{
		opacity: .5;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: .5;
	}
}

.midjourney-creative {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 15px 0;
	border-top: 1px solid #eee;

	&:first-child {
		border-top: 0;
	}

	div.top {
		display: flex;
		gap: 10px;

		div.intro {
			display: flex;
			flex-direction: column;
			gap: 10px;
			flex: 1;

			div.style {
				display: inline-block;
				color: #282828;
				padding: 4px 6px;
				border-radius: 4px;
				font-size: 11px;
				font-weight: 500;
				background-color: #eee;
			}

			div.prompt {
				color: #343434;
				font-size: 13px;
				font-style: italic;
				border-radius: 8px;
				padding: 6px 10px;
				border: 2px solid #eee;
			}
		}

		button {
			width: 35px;
			height: 35px;
			min-width: 35px;
			min-height: 35px;

			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;

			color: #333333;
			border: 0;
			outline: 0;
			box-shadow: none;
			background-color: #fff;

			&:hover {
				background-color: #eee;
			}
		}
	}

	div.results {
		position: relative;

		div.loader {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			z-index: 2;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

			display: flex;
			align-items: center;
			gap: 6px;
			height: 40px;
			border-radius: 40px;
			padding: 0 15px;
			background-color: #fff;
		}

		div.images {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			gap: 15px;

			> * {
				position: relative;
				flex-basis: calc(50% - 7.5px);

				> div {
					position: relative;
					border-radius: 8px;
					padding-bottom: 100%;
					overflow: hidden;
					background-color: #eee;
				}

				&.placeholder {
					animation: blink 1.5s ease-in-out infinite;
				}
			}

			> div.creative {
				cursor: pointer;

				div.actions {
					display: none;

					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;

					align-items: center;
					justify-content: center;
					gap: 10px;

					backdrop-filter: blur(5px);
					background-color: rgba(0, 0, 0, .5);

					z-index: 2;

					> div {
						display: flex;
						align-items: center;
						justify-content: center;

						width: 35px;
						height: 35px;
						border-radius: 35px;
						background-color: #fff;
					}
				}

				&:hover {
					div.actions {
						display: flex;
					}
				}

				img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					object-fit: contain;
				}
			}
		}
	}
}
</style>
