const BaseService = require('@/services/BaseService')

const namespace = 'integrations/midjourney/images'

const find = (id, cb, errorCb) => {
	BaseService.get(`${namespace}/${id}`, {}, cb, errorCb)
}

const findAll = (body, cb, errorCb) => {
	BaseService.get(namespace, body, cb, errorCb)
}

const generate = (data, cb, errorCb) => {
	BaseService.post(`${namespace}/generate`, data, cb, errorCb)
}

const remove = (id, cb, errorCb) => {
	BaseService.remove(`${namespace}/${id}`, {}, cb, errorCb)
}

export default {
	find,
	findAll,
	generate,
	remove
}
