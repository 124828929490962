import { render, staticRenderFns } from "./FacebookPostElement.vue?vue&type=template&id=e1b7c66a&scoped=true&"
import script from "./FacebookPostElement.vue?vue&type=script&lang=js&"
export * from "./FacebookPostElement.vue?vue&type=script&lang=js&"
import style0 from "./FacebookPostElement.vue?vue&type=style&index=0&id=e1b7c66a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1b7c66a",
  null
  
)

export default component.exports