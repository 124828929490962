<template>
	<div :style="{ width, height }"
		 class="image">
		<div class="placeholder">
			<div>
				<div class="actions">
					<div @click="onSelectImage">
						<i class="fa fa-plus fa-fw" />
					</div>

					<div v-if="!! value"
						 @click="onClear">
						<i class="fa fa-trash-o fa-fw" />
					</div>
				</div>
			</div>
		</div>

		<img v-if="!! value"
			 :src="value"
		/>

		<modern-modal v-if="showModal"
					  @close="showModal = false">
			<div slot="body">
				<template v-if="creatives.length > 0">
					<div class="creatives">
						<midjourney-creative v-if="creatives.length > 0"
											 v-for="creative in creatives"
											 :creative="creative"
											 :key="`creative-${creative.id}`"
											 @add="$path => onSelect($path)"
											 @remove="() => {}"
						/>
					</div>

					<div class="pagination-wrapper">
						<pagination :total="total"
									:limit="limit"
									:page="page"
									v-on:paginate="paginate"
						/>
					</div>
				</template>

				<div v-if="creatives.length === 0"
					 class="instruction">
					<div class="icon">
						<i class="fa fa-picture-o" />
					</div>

					<div class="headline"
						 v-text="$t('copilot.noImages.headline')"
					/>

					<div class="description"
						 v-text="$t('copilot.noImages.description')"
					/>

					<div class="instruction"
						 v-html="$t('copilot.noImages.instruction')"
					/>
				</div>
			</div>
		</modern-modal>
	</div>
</template>

<script>
import Modal from '@/app/layout/components/Modal'
import MidjourneyService from '@/services/ai/MidjourneyService'
import MidjourneyCreative from '@/app/copilot/components/MidjourneyCreative'
import Pagination from '@/app/layout/components/paginator/Pagination'
import ModernModal from "@/app/layout/components/ModernModal";

export default {
	props: {
		value: {},

		width: {
			required: false,
			default: '100%'
		},

		height: {
			required: false,
			default: '500px'
		}
	},

	data: () => ({
		creatives: [],
		page: 1,
		limit: 6,
		total: null,
		showModal: false
	}),

	computed: {
		internalValue: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	watch: {
		page() {
			this.loadImages()
		}
	},

	mounted() {
		this.loadImages()
	},

	methods: {
		onSelect(path) {
			this.internalValue = path
			this.page = 1

			this.showModal = false
		},

		onClear() {
			this.internalValue = null
		},

		loadImages() {
			MidjourneyService.findAll({
				sort: [{
					key: 'created',
					direction: 'DESC'
				}],

				includes: ['midjourneyImageResults'],

				page: this.page,
				limit: this.limit
			}, (response) => {
				this.creatives = response.data.rows
				this.total = response.data.total
			})
		},

		onSelectImage() {
			this.showModal = true

			this.loadImages()
		},

		paginate(page) {
			this.page = page
		}
	},

	components: {
		ModernModal,
		MidjourneyCreative,
		Pagination,
		Modal
	}
}
</script>

<style lang="scss" scoped>
.image {
	cursor: pointer;
	background-color: #eee;

	.placeholder {
		display: none;
		position: absolute;

		align-items: center;
		justify-content: center;

		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;

		background-color: rgba(0, 0, 0, .45);

		div.actions {
			display: flex;
			align-items: center;
			gap: 20px;

			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				width: 50px;
				height: 50px;
				border-radius: 16px;
				background-color: #fff;
			}
		}
	}

	> img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 100%;
		object-fit: cover;

		z-index: 1;
	}

	&:hover {
		.placeholder {
			display: flex;
		}
	}
}

.creatives {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.pagination-wrapper {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.instruction {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	.icon {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background: #eee;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
	}

	.headline {
		font-size: 18px;
		font-weight: 800;
	}

	.description {
		color: #353535;
		font-size: 13px;
		text-align: center;
		padding: 0 50px;
	}

	.instruction {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;
		flex-direction: row;
		gap: 12px;
		font-size: 13px;
		font-weight: 400;
		font-variant-numeric: tabular-nums;
		background-color: #f7f7f7;
		border: 1px solid #e1e1e1;
		border-radius: 6px;
		padding: 10px 15px;
		margin-top: 15px;

		> span {
			font-weight: 600;
			font-size: 60%;
		}
	}
}
</style>
