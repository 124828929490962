const BaseService = require('@/services/BaseService')

const namespace = 'co-pilot/business-context'

const get = (options, cb, errorCb) => {
	BaseService.get(namespace, options, cb, errorCb)
}

const update = (options, cb, errorCb) => {
	BaseService.put(namespace, options, cb, errorCb)
}

const refresh = (data, cb, errorCb) => {
	BaseService.post(namespace, data, cb, errorCb)
}

const remove = (data, cb, errorCb) => {
	BaseService.remove(namespace, data, cb, errorCb)
}

export default {
	get,
	update,
	refresh,
	remove
}
