<template>
	<div class="co-pilot">
		<div class="notifications">
			<div v-for="notification in notifications"
				 :key="`notification-${notification.id}`"
				 class="notification"
				 :class="{ removing: notificationsRemoving.includes(notification.id) }"
				 @click="removeNotification(notification.id)"
				 @mouseenter="mouseOverNotifications"
				 @mouseleave="mouseOutNotifications">
				<strong>{{ notification.title }}</strong>
				<span>{{ notification.description }}</span>
			</div>
		</div>

		<template v-if="!! item">
			<div class="top-bar">
				<router-link :to="backLink" class="logo">
					<img src="/images/opn/logo.svg" />
					<span>Co-pilot</span>
				</router-link>

				<ul v-if="1 === 3"
					class="navigation">
					<li>
						<a class="menu-item"
						   href="#">
							Indstillinger
						</a>
					</li>
				</ul>

				<ul class="navigation right">
					<li>
						<button type="button"
								class="menu-item"
								@click="undo"
								:disabled="history.before.length === 0">
							<i class="fa fa-rotate-left" />
						</button>
					</li>

					<li>
						<button type="button"
								@click="redo"
								class="menu-item"
							    :disabled="history.after.length === 0">
							<i class="fa fa-rotate-right" />
						</button>
					</li>
				</ul>
			</div>

			<div class="content">
				<div class="content-inner">
					<div class="document-title">
						<div class="inner">
							<document-title :title="(item || {}).title"
											@update="onSaveTitle"
							/>

							<div v-if="! dirty" class="status">{{ $t('copilot.saving.saved') }}</div>
							<div v-else class="status">{{ $t('copilot.saving.saving') }}</div>
						</div>

						<div class="word-counter">{{ $tc('copilot.words', words, { words }) }}</div>
					</div>

					<div v-if="exporting"
						 class="exporting">
						<i class="fa fa-spinner fa-spin fa-fw" />
						<span>{{ $t('copilot.exporting') }}</span>
					</div>

					<div v-if="generating"
						 class="generating">
						<i class="fa fa-spinner fa-spin fa-fw" />
						<span>{{ $t('copilot.generating') }}</span>
					</div>

					<div class="content-wrapper">
						<div v-click-outside="onBlur"
							 class="inner">
							<content-element v-for="(element, index) in elements"
											 :key="`element-${index}`"
											 :ref="`element-${index}`"
											 :is-first="index === 0"
											 :is-last="index === elements.length - 1"
											 :index="index"
											 @moveUp="onMove(index, 'up')"
											 @moveDown="onMove(index, 'down')"
											 :active="elementIndex === index"
											 :generating="generating"
											 :element="element"
											 :item="item"
											 :wordpress="wordpress"
											 @click.native="onFocus(index)"
											 @generating="setGenerating"
											 @exporting="setExporting"
											 @update="$payload => onUpdateElement(index, $payload)"
											 @focus="onFocus(index)"
											 @remove="onRemove(index)"
											 @changePrompt="onChangePrompt(index)"
							/>

							<div v-if="elements.length === 0"
								 class="quick-add">
								<div class="title">{{ $t('copilot.quickAdd.title') }}</div>

								<div class="content-types">
									<div v-for="element in featuredElements"
										 :key="`element-${element.slug}`"
										 @click="onCreateElement(element.slug)"
										 class="content-type">
										<div class="emoji">{{ element.icon }}</div>
										<div class="title">{{ element.title }}</div>
										<div class="subtitle">{{ element.type }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="menu"
					 :class="{ active: tab !== null }">
					<div class="sub-menu">
						<a @click.prevent.stop="setTab('content')"
						   :class="{ active: tab === 'content', largeActive: tab === null }"
						   href="#">
							<div>
								<i class="fa fa-list fa-fw" />
								<span>{{ $t('copilot.sidebar.content') }}</span>
							</div>
						</a>
						<a @click.prevent.stop="setTab('creatives')"
						   :class="{ active: tab === 'creatives' }"
						   href="#">
							<div>
								<i class="fa fa-image fa-fw" />
								<span>{{ $t('copilot.sidebar.images') }}</span>
							</div>
						</a>
					</div>

					<!-- Content -->
					<div v-if="tab === 'content' || tab === null"
						 class="content-types">
						<div class="search">
							<input type="text"
								   v-model="query"
								   :placeholder="$t('copilot.sidebar.search')"
							/>

							<button v-if="String(query || '').length > 0"
									@click="query = ''"
									type="button">
								<i class="fa fa-times" />
							</button>
						</div>

						<div v-for="element in filteredAvailableElements"
							 :key="`element-${element.slug}`"
							 @click="onCreateElement(element.slug)"
							 class="content-type">
							<div class="icon">{{ element.icon }}</div>
							<div class="text">
								<div class="title">{{ element.title }}</div>
								<div class="description">{{ element.type }}</div>
							</div>
						</div>
					</div>

					<!-- Creatives -->
					<div v-if="tab === 'creatives'"
						 class="creatives">
						<creatives :customerId="customerId"
								   @add="onAddImage"
								   :creatives="creatives"
								   @update="onLoadCreatives"
								   @paginate="onPaginateCreatives"
						/>
					</div>
				</div>
			</div>

			<form v-if="!! updateElement && ! generating"
				  @submit.prevent="onGenerate">
				<modern-modal @close="updateElement = null">
					<div slot="header">
						<div class="title">{{ $t('copilot.modal.title') }}</div>
						<div class="subtitle">{{ $t('copilot.modal.subtitle') }}</div>
					</div>

					<div slot="body"
						 class="updateElement">
						<div v-for="key in Object.keys(updateFields)"
							 :key="`field-${key}`">
							<!-- String -->
							<template v-if="updateFields[key].type === 'string'">
								<label for="title">{{ $t(`copilot.elements.${updateElement.slug}.input.${key}.label`) }}</label>

								<textarea class="form-control"
										  v-model="updateElement.input[key]"
								/>
							</template>

							<!-- Option -->
							<template v-if="updateFields[key].type === 'option'">
								<label for="title">{{ $t(`copilot.elements.${updateElement.slug}.input.${key}.label`) }}</label>

								<div class="options">
									<div v-for="(option, index) in updateFields[key].options"
										 :key="`option-${index}`"
										 class="option"
										 :class="{ active: updateElement.input[key] === option.key }"
										 @click="updateElement.input[key] = option.key">
										<i v-if="!! option.icon" :class="option.icon" />
										<span v-else-if="!! option.emoji" v-text="option.emoji" class="emoji" />
										<span>{{ $t(`copilot.elements.${updateElement.slug}.input.${key}.options.${option.key}`) }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div slot="footer"
						 class="d-flex justify-content-end">
						<button type="submit"
								:disabled="! canCreate"
								class="btn btn-dark">
							{{ $t('copilot.modal.button') }}
						</button>
					</div>
				</modern-modal>
			</form>
		</template>

		<div v-else
			 class="loader">
			<div class="icon">
				<i class="fa fa-spinner fa-spin fa-fw" />
			</div>

			<div class="text">{{ $t('copilot.loading') }}</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

import CoPilotItemService from '@/services/ai/CoPilotItemService'

import ContentElement from '@/app/copilot/components/Element'
import Modal from '@/app/layout/components/Modal'

import elements from '@/app/copilot/constants/elements'
import tones from '@/app/copilot/constants/tones'
import Qs from 'qs'
import {API_URL} from '@/config/env'
import TokenService from '@/services/_app/storage/TokenService'
import XhrStream from 'xhr-stream'
import {mapGetters} from 'vuex'

var partialParse = require('partial-json-parser')

import debounce from 'debounce'
import Creatives from '@/app/copilot/components/Creatives'
import DocumentTitle from '@/app/copilot/components/DocumentTitle'
import MidjourneyService from '@/services/ai/MidjourneyService'
import ModernModal from '@/app/layout/components/ModernModal'
import WordPressService from '@/services/wordpress/WordPressService'

export default {
	directives: {
		ClickOutside
	},

	data: () => ({
		elements: [],

		item: null,
		generating: false,
		exporting: false,
		elementIndex: null,

		updateElement: null,
		elementData: {},

		_initialized: false,
		debounceSave: null,

		tab: null,
		query: '',

		history: {
			updating: false,
			before: [],
			after: [],
			cache: null
		},

		debounceHistory: null,

		dirty: false,

		creatives: {
			page: 1,
			limit: 4,
			total: null,
			interval: null,
			items: []
		},

		notifications: [],
		notificationsHovered: false,
		notificationTimeouts: [],
		notificationsRemoving: [],

		wordpress: null
	}),

	watch: {
		json: {
			handler(after, before) {
				if (! this._initialized) {
					return
				}

				// History

				if (! this.history.updating &&
					after !== before &&
					before !== JSON.stringify([])
				) {
					if (this.history.cache === null) {
						this.$set(this.history, 'cache', JSON.parse(before))
					}

					this.debounceHistory()
				}

				this.debounceSave()
				this.dirty = true
			}
		},

		item() {
			this.elements = JSON.parse(JSON.stringify((this.item || {}).elements || []))
		},

		customerId() {
			this.onLoadCreatives()
		}
	},

	computed: {
		...mapGetters('identity', {
			identity: 'getIdentity'
		}),

		backLink() {
			if (!! this.identity && this.identity.discr === 'employee') {
				return { name: 'backend.customers.view.copilot', params: { id: this.customerId } }
			}

			return { name: 'copilot' }
		},

		canCreate() {
			const element = this.updateElement

			if (! element) {
				return false
			}

			const updateFields = this.updateFields

			return Object.keys(updateFields)
				.reduce(
					(carry, key) => {
						return carry && String(element.input[key] || '').length > 0
					},
					true
				)
		},

		json() {
			return JSON.stringify(this.elements)
		},

		customerId() {
			return ((this.item || {}).customer || {}).id || null
		},

		id() {
			return this.$route.params.id
		},

		availableElements() {
			return elements
				.filter(element => element.visible !== false)
				.map(element => ({
					...JSON.parse(JSON.stringify(element)),
					title: this.$t(`copilot.elements.${element.slug}.title`),
					type: this.$t(`copilot.elementTypes.${element.type}`)
				}))
		},

		filteredAvailableElements() {
			const query = String(this.query || '').toLowerCase()

			if (query.length === 0) {
				return this.availableElements
			}

			return this.availableElements.filter(item => {
				return String(item.title || '').toLowerCase().includes(query) ||
					String(item.type || '').toLowerCase().includes(query)
			})
		},

		featuredElements() {
			return this.availableElements.filter(element => element.featured)
		},

		tones() {
			return Object.keys(tones)
				.reduce(
					(carry, key) => ({
						...carry,
						[key]: {
							icon: tones[key].icon,
							title: this.$t(`copilot.tones.${key}`)
						}
					}),
					{}
				)
		},

		updateFields() {
			if (! this.updateElement) {
				return
			}

			const structure = elements.find(element => element.slug === this.updateElement.slug)

			if (! structure) {
				return []
			}

			return JSON.parse(JSON.stringify(structure.input || {}))
		},

		words() {
			const elements = this.elements || []

			return elements.reduce(
				(carry, element) => {
					const text = element.text || {}

					const plus = Object.values(text).reduce(
						(c, i) => String(i || '').length > 0
							? c + String(i || '').split(' ').length
						    : c,
						0
					)

					return carry + plus
				},
				0
			)
		}
	},

	mounted() {
		this.load()
		this.loadWordPress()
		this.onLoadCreatives()

		this.debounceSave = debounce(this.onSave, 1000)
		this.debounceHistory = debounce(this.saveHistory, 1000)
	},

	beforeDestroy() {
		!! this.creatives.interval && clearInterval(this.creatives.interval)
	},

	methods: {
		loadWordPress() {
			WordPressService.get(
				{},
				({ data }) => {
					this.wordpress = data
				},
				(response) => {
				}
			)
		},

		createNotification(notification) {
			var n = Math.floor(Math.random() * 11)
			var k = Math.floor(Math.random() * 1000000)

			const id = String.fromCharCode(n) + k

			this.notifications.push({
				...notification,
				id
			})

			if (! this.notificationsHovered) {
				this.notificationTimeouts.push(
					setTimeout(() => {
						this.removeNotification(id)
					}, 4000)
				)
			}
		},

		mouseOverNotifications() {
			this.notificationTimeouts.forEach(timeout => clearTimeout(timeout))
			this.notificationTimeouts = []

			this.notificationsHovered = true
		},

		mouseOutNotifications() {
			this.notifications.forEach(notification => {
				this.notificationTimeouts.push(
					setTimeout(() => {
						this.removeNotification(notification.id)
					}, 4000)
				)
			})

			this.notificationsHovered = false
		},

		removeNotification(id) {
			if (this.notificationsRemoving.includes(id)) {
				return
			}

			this.notificationsRemoving.push(id)

			setTimeout(() => {
				this.notificationsRemoving.splice(
					this.notificationsRemoving.indexOf(id),
					1
				)

				this.notifications = this.notifications.filter(i => i.id !== id)
			}, 800)
		},

		onMove(index, direction) {
			const copy = JSON.parse(JSON.stringify(this.elements))

			const to = direction === 'up'
				? index - 1
				: index + 1

			const temp = copy[index]

			copy[index] = copy[to]
			copy[to] = temp

			this.elements = copy

			if (this.elementIndex !== null) {
				direction === 'up'
					? this.elementIndex--
					: this.elementIndex++
			}

			// Scroll into view

			this.$nextTick(() => {
				const $element = (this.$refs[`element-${this.elementIndex}`] || [])[0]

				!! $element && $element.$el.scrollIntoView({
					behavior: 'smooth',
					inline: 'start'
				})
			})
		},

		setTab(tab) {
			this.tab = this.tab !== tab
				? tab
				: null
		},

		onAddImage(path) {
			this.elements.push({
				slug: 'image.image',
				input: {},
				images: {
					image: path
				},
				prompt: {}
			})
		},

		saveHistory() {
			const value = JSON.parse(JSON.stringify(this.history.cache))

			this.$set(this.history, 'before', [
				...this.history.before,
				value
			])

			this.$set(this.history, 'after', [])
			this.$set(this.history, 'cache', null)
		},

		redo() {
			if (! this.history.after.length) {
				return
			}

			this.$set(
				this.history,
				'updating',
				true
			)

			const after = [ ...this.history.after ]

			const current = { ...this.elements }

			this.elements = after.shift()

			this.$set(
				this.history,
				'after',
				after
			)

			this.$set(
				this.history,
				'before',
				[
					...this.history.before,
					current
				]
			)

			this.$nextTick(() => {
				this.$set(
					this.history,
					'updating',
					false
				)
			})
		},

		undo() {
			if (! this.history.before.length) {
				return
			}

			this.$set(
				this.history,
				'updating',
				true
			)

			const before = [ ...this.history.before ]

			const next = before.pop()

			const current = { ...this.elements }
			this.elements = next

			this.$set(
				this.history,
				'before',
				before
			)

			this.$set(
				this.history,
				'after',
				[
					current,
					...this.history.after
				]
			)

			this.$nextTick(() => {
				this.$set(
					this.history,
					'updating',
					false
				)
			})
		},

		onCreateElement(slug) {
			const structure = elements.find(element => element.slug === slug)

			if (! structure) {
				return
			}

			this.updateElement = {
				slug,

				input: Object.keys(structure.input || {}).reduce(
					(carry, item) => {
						let defaultValue = ''

						if (structure.input[item].type === 'option') {
							defaultValue = structure.input[item].options[0].key
						}

						return {
							...carry,
							[item]: defaultValue
						}
					},
					{}
				),

				images: (structure.images || []).reduce(
					(carry, key) => ({
						...carry,
						[key]: null
					}),
					{}
				),

				text: (structure.text || []).reduce(
					(carry, key) => ({
						...carry,
						[key]: ''
					}),
					{}
				),

				prompt: {}
			}
		},

		onChangePrompt(index) {
			const copy = JSON.parse(JSON.stringify(this.elements[index]))
			copy.index = index

			this.updateElement = copy
		},

		onGenerate() {
			if (! this.updateElement) {
				return
			}

			const element = JSON.parse(JSON.stringify(this.updateElement))

			let index = element.index
			delete element.index

			if (index === undefined || index === null) {
				index = this.elements.push(element) - 1
			}

			this.elementIndex = index

			this.updateElement = null

			// Generate text

			this.generating = true

			const promise = new Promise((resolve) => {
					const query = Qs.stringify({
						...element,
						item_id: this.item.id,
						customerId: this.customerId
					})

					const xhr = new XMLHttpRequest()

					xhr.open('POST', `${API_URL}/co-pilot/actions/generate?${query}`, true)
					xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

					const stream = new XhrStream( xhr )

					let text = ''

					stream.on('data', (response) => {
						let additional = response.toString()
						additional = additional.replace(/(\r\n|\n|\r)/gm, "")

						text = `${text}${additional}`

						const errorText = `{"error"`

						const length = text.length > errorText.length
							? errorText.length
							: text.length

						if (text.substring(0, length) === errorText.substring(0, length)) {
							return
						}

						let parsed = null
						let placeholder = null

						try {
							placeholder = partialParse(`${text || '{}'}"}`)

							if (!! placeholder) {
								parsed = placeholder
							}
						} catch (throwable) {
						}

						if (parsed === null) {
							try {
								placeholder = partialParse(text || '{}')

								if (!! placeholder) {
									parsed = placeholder
								}
							} catch (throwable) {
							}
						}

						if (parsed === null) {
							return
						}

						const keys = Object.keys(parsed)

						keys.forEach(key => {
							element.text[key] = this.parsedContent(parsed[key])
						})

						this.$set(this.elements, index, element)
					})

					stream.on('end', () => {
						const errorText = `{"error"`

						const length = text.length > errorText.length
							? errorText.length
							: text.length

						if (text.substring(0, length) === errorText.substring(0, length)) {
							this.createNotification({
								title: 'The request could not be performed',
								description: 'It was not possible to create your content as the connection was suddenly lost. We have registered the incident, which will be investigated by our technicians.'
							})

							resolve()
							return
						}

						let parsed = null
						let placeholder = null

						try {
							placeholder = partialParse(`${text || '{}'}"}`)

							if (!! placeholder) {
								parsed = placeholder
							}
						} catch (throwable) {
						}

						if (parsed === null) {
							try {
								placeholder = partialParse(text || '{}')

								if (!! placeholder) {
									parsed = placeholder
								}
							} catch (throwable) {
							}
						}

						if (parsed === null) {
							this.createNotification({
								title: 'The request could not be performed',
								description: 'It was not possible to create your content as the connection was suddenly lost. We have registered the incident, which will be investigated by our technicians.'
							})

							resolve()
							return
						}

						const keys = Object.keys(parsed)

						keys.forEach(key => {
							element.text[key] = parsed[key]
						})

						this.elements[index] = element

						resolve()
					})

					stream.on('error', () => {
						resolve()
					})
			})

			promise.finally(() => {
				this.generating = false
			})
		},

		parsedContent(input) {
			if (input.endsWith('</')) {
				input = input.substring(0, -2)
			}

			if (input.endsWith('<')) {
				input = input.substring(0, -1)
			}

			return input
		},

		load() {
			CoPilotItemService.find(
				this.id,
				{
					includes: ['customer']
				},
				({ data }) => {
					this.item = data
					this.dirty = false

					this.$nextTick(() => {
						this._initialized = true
					})
				},
				() => {
					this.$router.replace('/')
				}
			)
		},

		onSaveTitle(title) {
			this.$set(this.item, 'title', title)
			this.dirty = true

			this.debounceSave()
		},

		onSave() {
			if (! this.item) {
				return
			}

			CoPilotItemService.put(
				this.item.id,
				{
					title: this.item.title,
					elements: this.elements
				},
				({ data }) => {
					this.dirty = false
				},
				() => {
					this.load()
				}
			)
		},

		onBlur() {
			this.elementIndex = null
		},

		onFocus(index) {
			setTimeout(() => {
				this.elementIndex = index
			}, 50)
		},

		onRemove(index) {
			this.elements.splice(index, 1)
			this.elementIndex = null
		},

		onUpdateElement(index, payload) {
			const copy = JSON.parse(JSON.stringify(this.elements[index]))

			this.$set(this.elements, index, this.deepMerge(copy, payload))
		},

		deepMerge(target, ...sources) {
			if (! sources.length) {
				return target
			}

			const source = sources.shift()

			if (this.isObject(target) &&
				this.isObject(source)
			) {
				for (const key in source) {
					if (this.isObject(source[key])) {
						if (!target[key]) Object.assign(target, { [key]: {} });
						this.deepMerge(target[key], source[key]);
					} else {
						Object.assign(target, { [key]: source[key] });
					}
				}
			}

			return this.deepMerge(target, ...sources)
		},

		isObject(item) {
			return (item && typeof item === 'object' && !Array.isArray(item));
		},

		setGenerating(state) {
			this.generating = state
		},

		setExporting(state) {
			this.exporting = state
		},

		uniqueId() {
			return Math.random().toString(16).slice(2)
		},

		onLoadCreatives() {
			if (! this.customerId) {
				return
			}

			const { page, limit } = this.creatives

			MidjourneyService.findAll({
				sort: [{
					key: 'created',
					direction: 'DESC'
				}],

				includes: ['midjourneyImageResults'],

				filter_groups: [{
					filters: [{
						key: 'customer',
						operator: 'eq',
						value: this.customerId
					}]
				}],

				page,
				limit
			}, (response) => {
				const items = response.data.rows

				const allIsCompleted = items.reduce(
					(carry, item) => {
						return carry && item.status === 'completed'
					},
					true
				)

				this.$set(this.creatives, 'items', items)
				this.$set(this.creatives, 'total', response.data.total)

				if (! allIsCompleted && ! this.creatives.interval) {
					const interval = setInterval(this.onLoadCreatives, 3000)
					this.$set(this.creatives, 'interval', interval)
				}

				if (allIsCompleted && !! this.creatives.interval) {
					clearInterval(this.creatives.interval)
					this.$set(this.creatives, 'interval', null)
				}
			})
		},

		onPaginateCreatives(page) {
			this.$set(this.creatives, 'page', page)
			this.onLoadCreatives()
		}
	},

	components: {
		ModernModal,
		DocumentTitle,
		Creatives,
		ContentElement,
		Modal
	}
}
</script>

<style lang="scss" scoped>
@keyframes notification-in {
	0% {
		transform: translateY(40px);
		opacity: 0;
		max-height: 0px;
	}

	50% {
		transform: translateY(40px);
		opacity: 0;
		max-height: 400px;
	}

	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes notification-out {
	0% {
		transform: translateY(0);
		opacity: 1;
	}

	50% {
		transform: translateY(40px);
		opacity: 0;
		max-height: 500px;
	}

	100% {
		transform: translateY(40px);
		opacity: 0;
		max-height: 0px;
	}
}

.co-pilot {
	display: flex;
	flex-direction: column;
	height: 100vh;
	background-color: #fff;

	> div.notifications {
		position: fixed;
		bottom: 25px;
		left: 25px;

		pointer-events: none;

		z-index: 99999999999;

		> div.notification {
			cursor: pointer;
			pointer-events: all;

			color: #fff;
			padding: 15px 20px;
			max-width: 500px;
			border-radius: 8px;
			overflow: hidden;
			backdrop-filter: blur(12px);

			display: flex;
			flex-direction: column;

			animation: notification-in .75s ease-in-out;
			max-height: 400px;

			margin-top: 10px;

			&.removing {
				animation: notification-out .75s ease-in-out;
				animation-fill-mode: forwards;
			}

			strong {
				font-size: 16px;
				font-weight: 500;
			}

			span {
				font-size: 14px;
				font-weight: 400;
			}

			> * {
				position: relative;
				z-index: 2;
			}

			&:before {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				content: '';
				opacity: .75;
				background-color: #1e1e1e;
				z-index: 1;
			}
		}
	}

	> .top-bar {
		position: relative;
		height: 60px;
		min-height: 60px;
		padding: 0 20px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
		z-index: 99;

		display: flex;
		align-items: center;
		gap: 25px;

		> .logo {
			display: flex;
			align-items: center;
			gap: 10px;

			img {
				height: 32px;
				fill: blue !important;
			}

			span {
				color: #000;
				font-weight: 600;
				font-size: 18px;
				letter-spacing: -.2px;
				white-space: nowrap;
			}
		}

		> ul.navigation {
			display: flex;
			align-items: center;
			gap: 10px;
			list-style: none;
			padding: 0;
			margin: 0;

			&.right {
				margin-left: auto;
			}

			> li {
				> .menu-item {
					display: block;
					color: #5d5d5d;
					font-size: 14px;
					padding: 6px 8px;
					font-weight: 500;
					border-radius: 6px;
					letter-spacing: -.15px;
					background-color: #fff;
					border: 0;

					&:hover {
						color: #000;
						background-color: #f3f3f3;
					}

					&:disabled {
						opacity: .4;
					}
				}
			}

			ul {
				display: none;
			}
		}
	}

	> div.content {
		display: flex;
		flex: 1;

		> div.content-inner {
			flex: 1;

			position: relative;

			> div.document-title {
				position: relative;

				display: flex;
				align-items: center;
				justify-content: space-between;

				padding: 0 20px;

				min-height: 46px;
				border-bottom: 1px solid #eee;

				background-color: #fff;

				z-index: 98;

				div.inner {
					display: flex;
					align-items: center;
					gap: 15px;

					line-height: 100%;

					> div.status {
						color: #757575;
						font-size: 13px;
						font-weight: 500;
					}
				}

				> div.word-counter {
					color: #444444;
					font-size: 12px;
					font-weight: 400;
				}
			}

			> div.generating,
			> div.exporting {
				position: absolute;
				bottom: 25px;
				left: 50%;

				z-index: 99;

				transform: translateX(-50%);

				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				height: 50px;
				border-radius: 50px;

				font-size: 14px;
				font-weight: 500;
				letter-spacing: -.15px;

				padding: 0 20px;

				background-color: #fff;
				box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
			}

			> div.content-wrapper {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				padding: 90px 0;

				overflow-y: scroll;

				> div.inner {
					width: 100%;
					max-width: 1200px;
					margin: 0 auto;

					div.quick-add {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 0 25px;

						> div.title {
							color: #000;
							font-size: 26px;
							font-weight: 600;
							margin: 0 0 30px 0;
						}

						> div.content-types {
							width: 100%;
							max-width: 800px;

							display: grid;
							gap: 20px;
							grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

							> div.content-type {
								display: flex;
								flex-direction: column;

								cursor: pointer;
								user-select: none;
								padding: 15px;
								border-radius: 8px;
								border: 1px solid #eee;

								&:hover {
									border-color: #000;
								}

								> .emoji {
									font-size: 26px;
									margin: 0 0 6px 0;
								}

								> .title {
									color: #000;
									font-weight: 500;
									font-size: 15px;
									letter-spacing: -.1px;
								}

								> .subtitle {
									color: #818181;
									font-weight: 400;
									font-size: 13px;
									letter-spacing: -.1px;
								}
							}
						}
					}
				}
			}
		}

		> div.menu {
			width: 400px;
			max-width: 400px;
			min-width: 400px;
			border-left: 1px solid #eee;
			background-color: #fff;

			height: calc(100vh - 60px);
			overflow-y: scroll;

			> div.sub-menu {
				position: sticky;
				top: 0;
				z-index: 9999;
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 6px 15px 0 15px;
				border-bottom: 1px solid #eee;
				background-color: #fff;

				> a,
				> button {
					color: #696969;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 40px;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: -.1px;
					border: 0;
					border-bottom: 2px solid transparent;
					margin-bottom: -1px;
					background: transparent;

					> div {
						display: flex;
						align-items: center;
						gap: 6px;
						padding: 6px 8px;
						border-radius: 6px;
						margin-bottom: 6px;
					}

					&:hover {
						> div {
							color: #000;
							background-color: #f6f6f6;
						}
					}

					&.active {
						color: #000;
						border-bottom-color: #000;
					}
				}
			}

			> div.creatives {
				padding: 15px;
			}

			> div.content-types {
				padding: 15px;

				display: flex;
				flex-direction: column;
				gap: 10px;

				> div.search {
					position: relative;

					button {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						width: 40px;
						z-index: 2;
						border: 0;
						box-shadow: none;
						outline: 0;
						font-size: 14px;
						background-color: transparent;
					}

					input {
						width: 100%;
						font-size: 13px;
						padding: 0 10px;
						height: 40px;
						border-radius: 6px;
						border: 1px solid #ccc;
					}
				}

				> div.content-type {
					cursor: pointer;

					user-select: none;

					display: flex;
					align-items: center;
					gap: 20px;
					border-radius: 8px;
					padding: 8px 15px;
					border: 2px solid #eee;
					background-color: #fff;

					> div.icon {
						font-size: 24px;
					}

					> div.text {
						display: flex;
						flex-direction: column;
						line-height: 125%;

						> div.title {
							color: #4d4d4d;
							font-size: 13px;
							font-weight: 500;
						}

						> div.description {
							color: #737373;
							font-size: 13px;
							font-weight: 400;
						}
					}

					&:hover {
						color: #000;
						border-color: #000;
					}
				}
			}
		}
	}

	.updateElement {
		display: flex;
		flex-direction: column;
		gap: 20px;

		label {
			color: #4b4b4b;
			font-weight: 500;
			font-size: 12px;
			letter-spacing: -.1px;
			text-transform: uppercase;
			margin: 0 0 6px 0;
		}

		div.options {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;

			> div.option {
				cursor: pointer;
				user-select: none;

				display: flex;
				align-items: center;
				gap: 6px;

				color: #4b4b4b;
				font-weight: 500;

				border: 1px solid #eee;
				padding: 12px 15px;
				border-radius: 8px;

				transition: all .25s ease-in-out;

				line-height: 100%;

				i,
				span.emoji {
					font-size: 120%;
				}

				&.active {
					color: #000;
					border-color: #000;
					box-shadow: inset 0 0 0px 1px #000;
				}
			}
		}
	}
}

.loader {
	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 15px;

	> div.icon {
		width: 60px;
		height: 60px;
		border-radius: 10px;
		background-color: #f3f3f3;

		display: flex;
		align-items: center;
		justify-content: center;

		font-size: 18px;
	}

	> div.text {
		color: #000;
		font-size: 13px;
		font-weight: 400;
		letter-spacing: -.1px;
	}
}

@media screen and (max-width: 992px) {
	.co-pilot {
		> div.content {
			> div.content-inner {
				> div.document-title {
					> div.inner {
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						gap: 4px;
						height: 55px;

						> div.status {
							font-size: 12px;
						}
					}
				}

				> div.content-wrapper {
					padding: 90px 0;
				}
			}

			> div.menu {
				position: absolute;
				top: calc(100% - 45px);
				right: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				min-width: 0;
				max-width: none;
				border-top: 1px solid #ccc;

				transition: top .4s, max-height 0s ease-in-out .6s;

				z-index: 9999;

				max-height: 45px;
				overflow-y: scroll;

				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}

				&.active {
					top: 0;
					max-height: 10000px;
					transition: top .4s, max-height 0s ease-in-out 0s;
				}
			}
		}
	}
}

@media screen and (min-width: 923px) {
	.co-pilot {
		> div.content {
			> div.menu {
				> div.sub-menu {
					> a,
					> button {
						&.largeActive {
							color: #000;
							border-bottom-color: #000;
						}
					}
				}
			}
		}
	}
}
</style>
