<template>
	<has-subscription feature="ai.content"
					  :title="$t('copilot.list.title')"
					  :description="$t('copilot.list.description')">
		<business-context />

		<card :headline="$t('copilot.list.title')"
			  :description="$t('copilot.list.description')">
			<div slot="header">
				<button type="button"
						class="add"
						@click="onShowCreateModal">
					<i class="fa fa-plus fa-fw" />
					<span>{{ $t('copilot.list.create') }}</span>
				</button>
			</div>

			<div v-if="items === null"
				 class="loader">
				<i class="fa fa-spinner fa-spin fa-fw" />
			</div>

			<div v-if="items !== null && items.length === 0"
				 class="placeholder">
				{{ $t('copilot.list.placeholder') }}
			</div>

			<div v-if="items !== null"
				 class="items">
				<div v-for="item in items"
					 :key="`item-${item.id}`"
					 class="item">
					<div class="left">
						<div class="title">{{ item.title }}</div>
						<div class="changed_at">{{ $t('copilot.list.changedAt', {date: formatDate(item.updated.date)}) }}</div>
					</div>

					<div class="right">
						<router-link :to="{name: 'copilot.view', params: {id: item.id}}">
							<span>{{ $t('copilot.list.open') }}</span>
						</router-link>

						<button type="button" class="delete" @click="onDelete(item.id)">
							<span><i class="fa fa-trash fa-fw" /></span>
						</button>
					</div>
				</div>
			</div>
		</card>

		<form v-if="showCreateModal"
			  @submit.prevent="onCreate">
			<modern-modal @close="showCreateModal = false">
				<div slot="header">
					<div class="title">{{ $t('copilot.list.modal.title') }}</div>
				</div>

				<div slot="body">
					<div class="form-group">
						<label for="title">{{ $t('copilot.list.modal.form.title') }}</label>

						<input type="text"
							   v-model="title"
							   class="form-control"
						/>
					</div>

					<div class="form-group">
						<label>{{ $t('copilot.list.modal.form.tone') }}</label>

						<div class="list-items">
							<div v-for="key in Object.keys(tones)"
								 :key="`tone-${key}`"
								 class="list-item"
								 @click="tone = key"
								 :class="{ active: tone === key }">
								<div class="emoji">{{ tones[key].icon }}</div>
								<div class="title">{{ tones[key].title }}</div>
							</div>
						</div>
					</div>

					<div class="form-group">
						<label>{{ $t('copilot.list.modal.form.audience') }}</label>

						<div class="list-items">
							<div v-for="key in Object.keys(audiences)"
								 :key="`audience-${key}`"
								 class="list-item"
								 @click="audience = key"
								 :class="{ active: audience === key }">
								<div class="emoji">{{ audiences[key].icon }}</div>
								<div class="title">{{ audiences[key].title }}</div>
							</div>
						</div>
					</div>

					<div class="form-group">
						<label>{{ $t('copilot.list.modal.form.language') }}</label>

						<div class="list-items">
							<div v-for="key in Object.keys(languages)"
								 :key="`audience-${key}`"
								 class="list-item"
								 @click="language = key"
								 :class="{ active: language === key }">
								<div class="emoji">{{ languages[key].icon }}</div>
								<div class="title">{{ languages[key].title }}</div>
							</div>
						</div>
					</div>
				</div>

				<div slot="footer" class="d-flex justify-content-end">
					<button :disabled="creating"
							type="submit"
							class="btn btn-dark py-3 px-4">
						<i v-if="creating"
						   class="fa fa-spinner fa-spin mr-2"
						/>
						{{ $t('copilot.list.modal.form.button') }}
					</button>
				</div>
			</modern-modal>
		</form>
	</has-subscription>
</template>

<style lang="scss" scoped>
.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #000;
	padding: 25px;
}

.placeholder {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #000;
	padding: 25px;
}

.add {
	display: flex;
	align-items: center;
	gap: 6px;
	color: #fff;
	font-weight: 600;
	font-size: 13px;
	padding: 12px 14px;
	border-radius: 6px;
	outline: 0;
	box-shadow: none;
	border: 0;
	letter-spacing: -.2px;
	background-color: #000;
}

.items {
	display: flex;
	flex-direction: column;

	> div.item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 25px 0;
		border-top: 1px solid #eee;

		div.title {
			color: #000;
			font-size: 17px;
			font-weight: 500;
		}

		.changed_at {
			color: #888888;
			font-weight: 400;
			font-size: 13px;
		}

		.right {
			display: flex;
			align-items: center;
			gap: 6px;
		}

		a,
		button {
			color: #fff !important;
			font-weight: 600 !important;
			font-size: 13px !important;
			padding: 12px 14px !important;
			border-radius: 6px !important;
			outline: 0 !important;
			box-shadow: none !important;
			border: 0 !important;
			letter-spacing: -.2px !important;
			background-color: #000;

			&.delete {
				background-color: #d53b3b;
			}
		}
	}
}

.list-items {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	gap: 10px;

	> div.list-item {
		cursor: pointer;
		user-select: none;

		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 14px;
		border-radius: 6px;
		border: 1px solid #d2d2d2;

		.icon {
			font-size: 18px;
		}

		.title {
			color: #484848;
			font-weight: 500;
			letter-spacing: -.1px;
			font-size: 13px;
		}

		&:hover {
			border-color: #000;
		}

		&.active {
			box-shadow: inset 0 0 0px 1px #000;
			border-color: #000;

			.title {
				color: #000;
			}
		}
	}
}
</style>

<script>
import CoPilotItemService from '@/services/ai/CoPilotItemService'

import {mapGetters} from "vuex";

import Modal from '../../layout/components/Modal'

import feature from '@/mixins/billing/feature'

import moment from 'moment'
import HasSubscription from '@/app/billing/components/HasSubscription'

import tones from '@/app/copilot/constants/tones'
import languages from '@/app/copilot/constants/languages'
import audiences from '@/app/copilot/constants/audiences'
import ModernModal from "@/app/layout/components/ModernModal";
import BusinessContext from "@/app/copilot/components/BusinessContext";

export default {
	mixins: [
		feature
	],

	data: () => ({
		items: null,
		creating: false,
		silent: true,

		showCreateModal: false,
		title: '',
		tone: 'friendly',
		audience: 'general',
		language: 'da_DK'
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		tones() {
			return Object.keys(tones)
				.reduce(
					(carry, key) => ({
						...carry,
						[key]: {
							icon: tones[key].icon,
							title: this.$t(`copilot.tones.${key}`)
						}
					}),
					{}
				)
		},

		audiences() {
			return Object.keys(audiences)
				.reduce(
					(carry, key) => ({
						...carry,
						[key]: {
							icon: audiences[key].icon,
							title: this.$t(`copilot.audiences.${key}`)
						}
					}),
					{}
				)
		},

		languages() {
			return JSON.parse(JSON.stringify(languages))
		}
	},

	watch: {
		billingFeatures() {
			this.loadItems()
		}
	},

	mounted() {
		this.loadItems()
	},

	methods: {
		onShowCreateModal() {
			this.showCreateModal = true
		},

		onCreate() {
			this.creating = true

			CoPilotItemService.post({
				title: this.title,
				tone: this.tone,
				language: this.language,
				audience: this.audience
			}, (response) => {
				const data = response.data

				this.showCreateModal = false

				this.creating = false

				this.$router.push({
					name: 'copilot.view',
					params: {
						id: data.id
					}
				})
			}, () => {
				this.creating = false
			})
		},

		loadItems() {
			if (! this.featuresHas('ai.content')) {
				return
			}

			CoPilotItemService.findAll({
				sort: [{
					key: 'updated',
					direction: 'DESC'
				}],

				filter_groups: [{
					filters: [
						{
							key: 'customer',
							operator: 'eq',
							value: this.customer.id
						},
						{
							key: 'inhouse',
							operator: 'eq',
							value: 0
						}
					]
				}]
			}, ({ data }) => {
				this.items = data.rows
			})
		},

		formatDate(date) {
			return moment(date).format('LLL')
		},

		onDelete(id) {
			this.$swal({
				text: this.$t('copilot.deleteModal.text'),
				type: 'warning',
				showCancelButton: true,
				confirmButtonText: this.$t('copilot.deleteModal.yes'),
				cancelButtonText: this.$t('copilot.deleteModal.no')
			}).then(() => {
				CoPilotItemService.remove(
					id,
					{},
					() => {
						this.loadItems()
					}
				)
			})
		}
	},

	components: {
		BusinessContext,
		ModernModal,
		HasSubscription,
		Modal
	}
}
</script>
