<template>
	<div class="modern-modal"
		 :class="{ closing }">
		<div class="inner">
			<div class="modern-modal-dialog">
				<button type="button"
						class="dialog-close"
						@click="onClose">
					<i class="far fa-x fa-fw" />
				</button>

				<div class="header">
					<slot v-if="!! $slots.header" name="header" />
				</div>

				<div class="body">
					<slot name="body" />
				</div>

				<div v-if="!! $slots.footer "
					 class="footer">
					<slot name="footer" />
				</div>
			</div>
		</div>

		<div @click="onClose"
			 class="overlay"
		/>
	</div>
</template>

<script>
export default {
	data: () => ({
		closing: false
	}),

	methods: {
		onClose() {
			this.closing = true

			setTimeout(() => {
				this.$emit('close')
			}, 600)
		}
	}
}
</script>

<style lang="scss" scoped>
@keyframes overlayIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes overlayOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes dialogIn {
	0% {
		opacity: 0;
		transform: translateY(100vw);
	}

	100% {
		opacity: 1;
		transform: translateY(0vw);
	}
}

@keyframes dialogOut {
	0% {
		opacity: 1;
		transform: translateY(0vw);
	}

	100% {
		opacity: 0;
		transform: translateY(100vw);
	}
}

.modern-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999999999;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:before,
	&:after {
		display: block;
		content: '';
	}

	> div.inner {
		position: relative;
		z-index: 2;

		width: 650px;
		max-width: 100vw;
		padding: 30px 20px;
		margin: 0 auto;

		transform: translateY(100vh);
		opacity: 0;

		animation: dialogIn .6s ease-in-out;
		animation-fill-mode: forwards;

		> div.modern-modal-dialog {
			position: relative;
			width: 100%;
			border-radius: 8px;
			overflow: hidden;
			background-color: #fff;

			max-height: calc(100vh - 200px);

			display: flex;
			flex-direction: column;

			> .dialog-close {
				position: absolute;
				top: 20px;
				right: 15px;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				border: 0;
				font-size: 16px;
				background-color: #fff;

				&:hover {
					background-color: #eee;
				}
			}

			> .header {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 2px;
				border-bottom: 1px solid #eee;

				min-height: 70px;
				padding: 12px 15px;

				overflow: hidden;

				flex-shrink: 0;

				::v-deep div.title {
					color: #2f2f2f;
					font-size: 14px;
					font-weight: 600;
				}

				::v-deep div.subtitle {
					color: #575757;
					font-size: 13px;
					font-weight: 400;
				}
			}

			> div.body {
				padding: 15px;

				flex: 1;

				overflow-y: scroll;

				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}
			}

			> div.footer {
				border-top: 1px solid #eee;
				padding: 15px;

				flex-shrink: 0;

				::v-deep .btn {
					opacity: 1 !important;
					padding: 14px 30px !important;
					font-size: 13px !important;
					letter-spacing: -.1px !important;
					font-weight: 600 !important;
					border: 0 !important;

					&:disabled {
						cursor: not-allowed !important;
						opacity: .5 !important;

						&:hover {
							background-color: inherit !important;
						}
					}
				}

				::v-deep .btn-dark {
					color: #fff !important;
					background-color: #343434 !important;

					&:hover {
						background-color: #000 !important;
					}
				}
			}
		}
	}

	.overlay {
		cursor: pointer;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		backdrop-filter: blur(4px);
		background-color: rgba(30, 30, 30, .5);
		z-index: 1;

		opacity: 0;

		animation: overlayIn .6s ease-in-out;
		animation-fill-mode: forwards;
	}

	&.closing {
		pointer-events: none;

		> div.inner {
			transform: translateY(0vh);
			opacity: 1;

			animation: dialogOut .6s ease-in-out;
			animation-fill-mode: forwards;
		}

		.overlay {
			opacity: 1;
			animation: overlayOut .6s ease-in-out;
			animation-fill-mode: forwards;
		}
	}
}


@media screen and (max-width: 768px) {
	.modern-modal {
		> div.inner {
			flex: 1;
			padding: 0;
			margin: 0;

			display: flex;
			flex-direction: column;

			> div.modern-modal-dialog {
				flex: 1;

				max-width: none;
				max-height: none;

				width: 100vw;

				border-radius: 0;
			}
		}
	}
}
</style>
