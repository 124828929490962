<template>
	<div class="editable-wrapper">
		<div v-if="!! id"
			 v-once
			 :contenteditable="! disabled"
			 class="editable"
			 ref="element"
			 @focus="onFocus"
			 @blur="onBlur"
			 @keydown="iterate"
			 @mousedown="iterate"
			 @input="update"
			 v-html="value"
			 tabindex="1"
			 :data-id="id"
		/>
	</div>
</template>

<style lang="scss" scoped>
.editable-wrapper {
	display: flex;
	flex-wrap: wrap;

	> div {
		min-width: 100%;
	}
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
	props: {
		value: {},

		active: {
			type: Boolean,
			required: false,
			default: false
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	data: () => ({
		id: null,
	}),

	watch: {
		value() {
			if (this.$refs.element.innerHTML === this.value) {
				return
			}

			this.$refs.element.innerHTML = this.value
		},

		disabled() {
			this.$refs.element.contentEditable = ! this.disabled
		}
	},

	mounted() {
		this.id = Date.now().toString(36) + Math.random().toString(36).substr(2)

		eventHub.$on('copilot.editable.updated', this.onEditableUpdated)
	},

	beforeDestroy() {
		eventHub.$off('copilot.editable.updated', this.onEditableUpdated)
	},

	methods: {
		onFocus() {
			this.$emit('focus')
		},

		onBlur() {
			this.$emit('blur')
		},

		iterate() {
			this.$emit('iterate')
		},

		onEditableUpdated(id) {
			if (this.id !== id) {
				return
			}

			this.$emit('input', this.$refs.element.innerHTML)
		},

		update($event) {
			this.$emit('input', $event.target.innerHTML)
		}
	}
}
</script>
