<template>
	<div class="creatives">
		<div class="generate">
			<div class="form-group">
				<label>{{ $t('copilot.elements.content.editor.creativeModal.prompt.label') }}</label>
				<textarea v-model="prompt"
						  class="form-control"
						  :placeholder="$t('copilot.elements.content.editor.creativeModal.prompt.placeholder')"
				/>
			</div>

			<div class="form-group">
				<label>{{ $t('copilot.elements.content.editor.creativeModal.style.label') }}</label>
				<div class="styles">
					<div v-for="item in styles"
						 @click="style = item"
						 :class="{ active: style === item }"
						 class="style"
						 :key="item">
						{{ $t(`copilot.elements.content.editor.creativeModal.style.${item}`) }}
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>{{ $t('copilot.elements.content.editor.creativeModal.aspectRatio.label') }}</label>
				<div class="aspect-ratios">
					<div @click="aspectRatio = '1:1'"
						 :class="{ active: aspectRatio === '1:1' }"
						 class="aspect-ratio aspect-1-1">
						1:1
					</div>

					<div @click="aspectRatio = '2:3'"
						 :class="{ active: aspectRatio === '2:3' }"
						 class="aspect-ratio aspect-2-3">
						2:3
					</div>

					<div @click="aspectRatio = '4:3'"
						 :class="{ active: aspectRatio === '4:3' }"
						 class="aspect-ratio aspect-4-3">
						4:3
					</div>

					<div @click="aspectRatio = '16:9'"
						 :class="{ active: aspectRatio === '16:9' }"
						 class="aspect-ratio aspect-16-9">
						16:9
					</div>
				</div>
			</div>

			<button type="button"
					class="btn btn-dark py-3 px-4"
					@click="generate">
				{{ $t('copilot.elements.content.editor.creativeModal.prompt.button') }}
			</button>
		</div>

		<div class="creatives">
			<midjourney-creative v-for="creative in creatives.items"
								 :creative="creative"
								 :key="`creative-${creative.id}`"
								 @add="$path => onAdd($path)"
								 @remove="onRemove(creative.id)"
			/>
		</div>

		<div class="pagination-wrapper">
			<pagination :total="creatives.total"
						:limit="creatives.limit"
						:page="creatives.page"
						v-on:paginate="paginate"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.generate {
	margin-bottom: 15px;

	button {
		color: #fff;
		letter-spacing: -.1px;
		font-weight: 500;
		opacity: 1 !important;
		background-color: #000 !important;
		border: 0;
		font-size: 14px;
		padding: 14px 25px !important;
	}
}

.creatives {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.pagination-wrapper {
	display: flex;
	justify-content: center;
}

.aspect-ratios {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;

	> div.aspect-ratio {
		cursor: pointer;
		user-select: none;

		display: flex;
		align-items: center;
		justify-content: center;

		color: #626262;
		font-size: 12px;
		font-weight: 500;

		width: 100%;
		height: 100%;
		max-width: 60px;
		max-height: 50px;
		border-radius: 3px;
		box-shadow: inset 0 0 0px 2px #fff;
		border: 1px solid #c4c4c4;
		background-color: #efefef;

		&.aspect-1-1 {
			aspect-ratio: 1 / 1;
		}

		&.aspect-16-9 {
			aspect-ratio: 16 / 9;
		}

		&.aspect-4-3 {
			aspect-ratio: 4 / 3;
		}

		&.aspect-2-3 {
			aspect-ratio: 2 / 3;
		}

		&:hover,
		&.active {
			border-color: #000;
		}

		&.active {
			color: #000;
		}
	}
}

.styles {
	display: flex;
	align-items: center;
	gap: 6px;
	flex-wrap: wrap;

	> .style {
		cursor: pointer;
		color: #7d7979;
		line-height: 100%;
		padding: 10px 14px;
		border-radius: 32px;
		border: 1px solid #eee;
		font-size: 13px;
		font-weight: 400;
		white-space: nowrap;

		&:hover {
			color: #000;
			border-color: #000;
		}

		&.active {
			color: #000;
			box-shadow: inset 0 0 0px 1px #000;
			border-color: #000;
		}
	}
}
</style>

<script>
import Pagination from '@/app/layout/components/paginator/Pagination'

import MidjourneyService from '@/services/ai/MidjourneyService'

import MidjourneyCreative from '@/app/copilot/components/MidjourneyCreative'


export default {
	props: {
		creatives: {
			type: Object,
			required: true
		},

		customerId: {
			type: String,
			required: true
		}
	},

	data: () => ({
		prompt: '',
		style: 'photorealistic',
		aspectRatio: '1:1',
		styles: [
			'photorealistic',
			'cartoon',
			'pixel_art',
			'drawing',
		]
	}),

	watch: {
		generating() {
			!! this.interval && clearInterval(this.interval)

			this.interval = setInterval(this.load, 3000)
		},

		customerId() {
			this.load()
		}
	},

	methods: {
		generate() {
			const style = this.style
			const prompt = this.prompt
			const aspectRatio = this.aspectRatio

			this.style = 'photorealistic'
			this.prompt = ''
			this.aspectRatio = '1:1'

			MidjourneyService.generate({
				prompt,
				style,
				aspectRatio,
				customerId: this.customerId
			}, () => {
				this.load()
			})
		},

		load() {
			this.$emit('update')
		},

		onRemove(id) {
			MidjourneyService.remove(id, () => {
				this.load()
			})
		},

		onAdd(path) {
			this.$emit('add', path)
		},

		paginate(page) {
			this.$emit('paginate', page)
		}
	},

	components: {
		MidjourneyCreative,
		Pagination
	}
}
</script>
