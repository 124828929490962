<template>
	<div v-else-if="element.slug === 'ads.googleExtendedTextAd'"
		 class="google-extended-text-ad">
		<div class="headline">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.headline1"
			/>
		</div>
		<div class="headline">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.headline2"
			/>
		</div>

		<div class="description">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.body1"
			/>
		</div>

		<div class="description">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.body2"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.google-extended-text-ad {
	max-width: 555px;
	margin: 0 auto;

	padding: 20px;

	box-shadow: rgba(140, 140, 140, 0.2) 0px 0px 0px 1px;
	border-radius: 8px;

	.headline {
		color: #1a0dab;
		font-size: 20px;
		line-height: 26px;
		font-family: arial,sans-serif;
	}

	.description {
		color: #4d5156;
		font-size: 14px;
		line-height: 1.58;
	}
}
</style>

<script>
import Editable from '@/app/copilot/components/Editable'

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		iterate() {
			this.$emit('iterate')
		},

		onFocus() {
			this.$emit('focus')
		}
	},

	components: {
		Editable
	}
}
</script>
