<template>
	<div class="document-title">
		<input type="text"
			   class="title"
			   :placeholder="placeholder"
			   @input="onUpdate"
			   :value="title"
			   :style="{ width: `${width}px` }"
		/>

		<div ref="text"
			 class="text">
			{{ title || placeholder }}
		</div>
	</div>
</template>

<style lang="scss" scoped>
.document-title {
	> input.title,
	> div.text {
		font-size: 18px;
		font-weight: 600;
	}

	> input.title {
		color: #000;
		outline: 0;
		border: 0;
		padding: 0;
		box-shadow: none;
	}

	> div.text {
		position: absolute;
		left: -100vw;
		top: -100vw;
		outline: 0;
		border: 0;
		padding: 0;
		box-shadow: none;
	}
}


@media screen and (max-width: 992px) {
	.document-title {
		> input.title,
		> div.text {
			font-size: 15px;
			font-weight: 500;
		}
	}
}
</style>

<script>
export default {
	props: {
		title: {}
	},

	data: () => ({
		width: 0
	}),

	computed: {
		placeholder() {
			return 'Unavngivet dokument'
		},

		internalValue: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	mounted() {
		this.setSize()

		window.addEventListener('resize', this.setSize)
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.setSize)
	},

	methods: {
		onUpdate($event) {
			this.$emit('update', $event.target.value)
			this.setSize()

			this.$nextTick(() => {
				this.setSize()
			})
		},

		setSize() {
			this.width = this.$refs.text.clientWidth + 5
		}
	}
}
</script>
