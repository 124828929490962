export default [
	// Text

	{
		icon: '✏️',
		slug: 'text.headline',
		type: 'text',

		featured: false,

		input: {
			description: {
				type: 'string'
			}
		},

		text: [
			'content'
		]
	},

	{
		icon: '✏️',
		slug: 'text.paragraph',
		type: 'text',

		featured: false,

		input: {
			description: {
				type: 'string'
			}
		},

		text: [
			'content'
		]
	},

	// Content

	{
		icon: '💡',
		slug: 'content.blogPost',
		type: 'content',
		featured: true,

		input: {
			temperature: {
				type: 'option',
				options: [
					{
						key: '100',
						emoji: '😊'
					},
					{
						key: '50',
						emoji: '😐',
					}
				]
			},

			length: {
				type: 'option',
				options: [
					{
						key: 'extra-short',
						icon: 'fa fa-signal',
					},
					{
						key: 'short',
						icon: 'fa fa-signal',
					},
					{
						key: 'medium',
						icon: 'fa fa-signal',
					},
					{
						key: 'long',
						icon: 'fa fa-signal',
					},
					{
						key: 'extra-long',
						icon: 'fa fa-signal',
					}
				]
			},

			context: {
				type: 'string'
			},

			target: {
				type: 'string'
			},

			objective: {
				type: 'string'
			},
		},

		images: [
			'image',
		],

		text: [
			'headline',
			'content'
		]
	},

	{
		icon: '🗞️',
		slug: 'content.article',
		type: 'content',
		featured: true,

		input: {
			temperature: {
				type: 'option',
				options: [
					{
						key: '100',
						emoji: '😊'
					},
					{
						key: '50',
						emoji: '😐',
					}
				]
			},

			length: {
				type: 'option',
				options: [
					{
						key: 'extra-short',
						icon: 'fa fa-signal',
					},
					{
						key: 'short',
						icon: 'fa fa-signal',
					},
					{
						key: 'medium',
						icon: 'fa fa-signal',
					},
					{
						key: 'long',
						icon: 'fa fa-signal',
					},
					{
						key: 'extra-long',
						icon: 'fa fa-signal',
					}
				]
			},

			context: {
				type: 'string'
			},

			target: {
				type: 'string'
			},

			objective: {
				type: 'string'
			},
		},

		images: [
			'image',
		],

		text: [
			'headline',
			'content'
		]
	},

	{
		icon: '📦',
		slug: 'content.productPage',
		type: 'content',
		featured: true,

		input: {
			temperature: {
				type: 'option',
				options: [
					{
						key: '100',
						emoji: '😊'
					},
					{
						key: '50',
						emoji: '😐',
					}
				]
			},

			length: {
				type: 'option',
				options: [
					{
						key: 'extra-short',
						icon: 'fa fa-signal',
					},
					{
						key: 'short',
						icon: 'fa fa-signal',
					},
					{
						key: 'medium',
						icon: 'fa fa-signal',
					},
					{
						key: 'long',
						icon: 'fa fa-signal',
					},
					{
						key: 'extra-long',
						icon: 'fa fa-signal',
					}
				]
			},

			context: {
				type: 'string'
			},

			target: {
				type: 'string'
			},

			objective: {
				type: 'string'
			},
		},

		images: [
			'image',
		],

		text: [
			'headline',
			'content'
		]
	},

	// Email

	{
		icon: '💌',
		slug: 'email.newsletter',
		type: 'email',
		featured: true,

		input: {
			temperature: {
				type: 'option',
				options: [
					{
						key: '100',
						emoji: '😊'
					},
					{
						key: '50',
						emoji: '😐',
					}
				]
			},

			length: {
				type: 'option',
				options: [
					{
						key: 'extra-short',
						icon: 'fa fa-signal',
					},
					{
						key: 'short',
						icon: 'fa fa-signal',
					},
					{
						key: 'medium',
						icon: 'fa fa-signal',
					},
					{
						key: 'long',
						icon: 'fa fa-signal',
					},
					{
						key: 'extra-long',
						icon: 'fa fa-signal',
					}
				]
			},

			context: {
				type: 'string'
			},

			target: {
				type: 'string'
			},

			objective: {
				type: 'string'
			},
		},

		images: [
			'image',
		],

		text: [
			'subject',
			'content'
		]
	},

	// Image

	{
		icon: '🖼️',
		slug: 'image.image',
		type: 'image',
		featured: false,
		visible: false,

		input: {},

		images: [
			'image',
		],

		text: []
	},

	// Social

	{
		icon: '💬',
		slug: 'social.linkedIn',
		type: 'social',
		featured: true,

		input: {
			description: {
				type: 'string'
			}
		},

		images: [
			'image',
		],

		text: [
			'post'
		]
	},

	{
		icon: '💬',
		slug: 'social.facebook',
		type: 'social',
		featured: true,

		input: {
			description: {
				type: 'string'
			}
		},

		images: [
			'image',
		],

		text: [
			'post'
		]
	},

	// Ads

	{
		icon: '🛒',
		slug: 'ads.googleExtendedTextAd',
		type: 'ads',
		featured: true,

		input: {
			target: {
				type: 'string'
			},

			description: {
				type: 'string'
			},

			action: {
				type: 'string'
			}
		},

		text: [
			'headline1',
			'headline2',
			'body1',
			'body2'
		]
	}
]
