<template>
	<div class="blog-post">
		<div class="image">
			<div>
				<ai-image width="100%"
						  height="100%"
						  v-model="internalElement.images.image"
				/>
			</div>
		</div>

		<div class="headline">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.headline"
			/>
		</div>

		<div class="content">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.content"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.blog-post {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.image {
		position: relative;
		width: 100%;
		padding-bottom: 40%;
		border-radius: 36px;
		overflow: hidden;
		margin-bottom: 10px;

		> div {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	div.headline {
		font-size: 36px;
		font-weight: 800;
	}

	div.content {
		display: flex;
		flex-direction: column;
		gap: 15px;

		font-size: 15px;
		font-weight: 400;

		.editable-wrapper * {
			margin: 0;
			padding: 0;
		}

		::v-deep h2 {
			font-size: 26px;
			font-weight: 800;
		}

		::v-deep h3 {
			font-size: 22px;
			font-weight: 700;
		}

		::v-deep h4 {
			font-size: 18px;
			font-weight: 700;
		}

		::v-deep h5 {
			font-size: 14px;
			font-weight: 600;
		}

		::v-deep h5 {
			font-size: 12px;
			font-weight: 500;
		}

		::v-deep p {
			font-size: 15px;
			font-weight: 400;
		}

		::v-deep ul,
		::v-deep ol {
			display: flex;
			flex-direction: column;
			gap: 10px;

			margin: 0;
			padding: 0;

			padding-inline-start: 15px;

			li {
				padding: 0 0 0 10px;
			}
		}
	}
}
</style>

<script>
import Editable from '@/app/copilot/components/Editable'
import AiImage from "@/app/copilot/components/AiImage";

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false,
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		iterate() {
			this.$emit('iterate')
		},

		onFocus() {
			this.$emit('focus')
		}
	},

	components: {
		AiImage,
		Editable
	}
}
</script>
