<template>
	<div class="image">
		<ai-image width="100%"
				  height="100%"
				  v-model="internalElement.images.image"
	    />
	</div>
</template>

<style lang="scss" scoped>
.image {
	position: relative;
	width: 100%;
	padding-bottom: 50%;

	> div {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}
</style>

<script>
import AiImage from '@/app/copilot/components/AiImage'

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	components: {AiImage}
}
</script>
