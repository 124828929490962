<template>
	<div class="headline">
		<editable :disabled="generating"
				  @iterate="iterate"
				  @focus="onFocus"
				  v-model="internalElement.text.content"
		/>
	</div>
</template>

<style lang="scss" scoped>
.headline {
	color: #000;
	font-size: 26px;
	font-weight: 600;
}
</style>

<script>
import Editable from '@/app/copilot/components/Editable'

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false,
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		iterate() {
			this.$emit('iterate')
		},

		onFocus() {
			this.$emit('focus')
		}
	},

	components: {
		Editable
	}
}
</script>
