<template>
	<div class="element"
		 :class="{ active, generating }">
		<div class="inline-editor"
			 :class="{ generating }"
			 @mouseleave="expanded = false">
			<div class="inner-label-placeholder"
				 v-text="label.placeholder || ''"
				 ref="labelPlaceholder"
			/>

			<div v-if="label.text !== null"
				 class="inner-label"
				 :style="{
					left: `${label.x}px`,
					top: `${label.y}px`,
					width: `${label.width.y}px`,
					height: `${label.height.y}px`,
					opacity: label.show ? 1 : 0
				 }"
				 v-text="label.text"
			/>

			<div class="inner">
				<div class="scroller">
					<div v-if="! isFirst || ! isLast"
						 class="button-group">
						<button v-if="! isFirst"
								type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.moveUp')"
								@click.stop.prevent="moveUp">
							<i class="fa fa-arrow-up fa-fw" />
						</button>

						<button v-if="! isLast"
								type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.moveDown')"
								@click.stop.prevent="moveDown">
							<i class="fa fa-arrow-down fa-fw" />
						</button>
					</div>

					<div v-if="toolbar.text"
						 class="button-group">
						<button type="button"
								class="button clickable"
								:class="{ active: formatting.h1 }"
								:data-label="$t('copilot.labels.headline1')"
								@click.stop.prevent="executeCommandRaw('formatBlock', false, '<h1>')">
							H1
						</button>

						<button type="button"
								class="button clickable"
								:class="{ active: formatting.h2 }"
								:data-label="$t('copilot.labels.headline2')"
								@click.stop.prevent="executeCommandRaw('formatBlock', false, '<h2>')">
							H2
						</button>

						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.headline3')"
								:class="{ active: formatting.h3 }"
								@click.stop.prevent="executeCommandRaw('formatBlock', false, '<h3>')">
							H3
						</button>

						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.clearFormatting')"
								@click="clearFormatting">
							<i class="fa fa-font fa-fw" />
							<span class="dash" />
						</button>
					</div>

					<div v-if="toolbar.format"
						 class="button-group">
						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.bold')"
								:class="{ active: formatting.bold }"
								@click.stop.prevent="executeCommand($event, 'Bold')">
							<i class="fa fa-bold fa-fw" />
						</button>

						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.italic')"
								:class="{ active: formatting.italic }"
								@click.stop.prevent="executeCommand($event, 'Italic')">
							<i class="fa fa-italic fa-fw" />
						</button>

						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.underline')"
								:class="{ active: formatting.underline }"
								@click.stop.prevent="executeCommand($event, 'Underline')">
							<i class="fa fa-underline fa-fw" />
						</button>
					</div>

					<div v-if="toolbar.format"
						 class="button-group">
						<button type="button"
								:data-label="$t('copilot.labels.continue')"
								@click.stop.prevent="onExtend"
								class="button clickable">
							▶️
						</button>

						<button type="button"
								:data-label="$t('copilot.labels.rewrite')"
								@click.stop.prevent="onRewrite"
								class="button clickable">
							🔁
						</button>

						<button type="button"
								:data-label="$t('copilot.labels.summarize')"
								@click.stop.prevent="onSummarize"
								class="button clickable">
							💬
						</button>
					</div>

					<div v-if="toolbar.align"
						 class="button-group">
						<button type="button"
								data-label="Venstrestil"
								class="button clickable"
								:data-label="$t('copilot.labels.alignLeft')"
								@click.stop.prevent="executeCommand($event, 'justifyLeft')">
							<i class="fa fa-align-left fa-fw" />
						</button>

						<button type="button"
								:data-label="$t('copilot.labels.alignCenter')"
								class="button clickable"
								@click.stop.prevent="executeCommand($event, 'justifyCenter')">
							<i class="fa fa-align-center fa-fw" />
						</button>

						<button type="button"
								:data-label="$t('copilot.labels.alignRight')"
								class="button clickable"
								@click.stop.prevent="executeCommand($event, 'justifyRight')">
							<i class="fa fa-align-right fa-fw" />
						</button>
					</div>

					<div v-if="toolbar.list"
						 class="button-group">
						<button type="button"
								class="button clickable"
								:data-label="$t('copilot.labels.ul')"
								:class="{ active: formatting.unorderedList }"
								@click.stop.prevent="executeCommandRaw('insertUnorderedList')">
							<i class="fa fa-list-ul fa-fw" />
						</button>

						<button type="button"
								:data-label="$t('copilot.labels.ol')"
								class="button clickable"
								:class="{ active: formatting.orderedList }"
								@click.stop.prevent="executeCommandRaw('insertOrderedList')">
							<i class="fa fa-list-ol fa-fw" />
						</button>
					</div>

					<div class="button-group">
						<div v-if="showSettings"
							 :data-label="$t('copilot.labels.ai')"
							 @click="expanded = expanded !== 'ai' ? 'ai' : false"
							 @mouseover="expanded = 'ai'"
							 class="button">
							<i class="fa fa-cog fa-fw" />
						</div>

						<div v-if="showExport"
							 :data-label="$t('copilot.labels.export')"
							 @click="expanded = expanded !== 'export' ? 'export' : false"
							 @mouseover="expanded = 'export'"
							 class="button">
							<i class="fa fa-download fa-fw" />
						</div>

						<div class="button clickable"
							 :data-label="$t('copilot.labels.delete')"
							 @click="onRemove">
							<i class="fa fa-trash-o fa-fw" />
						</div>
					</div>
				</div>

				<div class="button-submenu"
					 :class="{ expanded: expanded === 'ai' }"
					 @mouseleave="expanded = false">
					<div v-if="submenu === null">
						<button type="button"
								@click.stop.prevent="onGenerate">
							<span class="emoji">🔁</span>
							<span>{{ $t('copilot.aiActions.regenerate') }}</span>
						</button>

						<button type="button"
								@click.stop.prevent="onChangePrompt">
							<span class="emoji">⚙️️</span>
							<span>{{ $t('copilot.aiActions.changePrompt') }}</span>
						</button>

						<button type="button"
								@click.stop.prevent="onRephrase">
							<span class="emoji">✏️</span>
							<span>{{ $t('copilot.aiActions.rephrase') }}</span>
						</button>

						<button type="button"
								@click.stop.prevent="onFixGrammar">
							<span class="emoji">✅️</span>
							<span>{{ $t('copilot.aiActions.grammar') }}</span>
						</button>

						<button type="button"
								@click.stop.prevent="submenu = 'tones'">
							<span class="emoji">😊️</span>
							<span>{{ $t('copilot.aiActions.tone') }}</span>
						</button>

						<button type="button"
								@click.stop.prevent="submenu = 'translations'">
							<span class="emoji">🌎️</span>
							<span>{{ $t('copilot.aiActions.translate') }}</span>
						</button>
					</div>

					<div v-if="submenu === 'translations'">
						<div @click.stop.prevent="submenu = null">
							<span>{{ $t('copilot.aiActions.back') }}</span>
						</div>

						<button v-for="key in Object.keys(languages)"
								type="button"
								@click.stop.prevent="onTranslate(key)">
							<span class="emoji">{{ languages[key].icon }}</span>
							<span>{{ languages[key].title }}</span>
						</button>
					</div>

					<div v-if="submenu === 'tones'">
						<div @click.stop.prevent="submenu = null">
							<span>{{ $t('copilot.aiActions.back') }}</span>
						</div>

						<button v-for="key in Object.keys(tones)"
								:key="`tone-${key}`"
								type="button"
								@click.stop.prevent="onTone(key)">
							<span class="emoji">{{ tones[key].icon }}</span>
							<span>{{ tones[key].title }}</span>
						</button>
					</div>
				</div>

				<div class="button-submenu"
					 :class="{ expanded: expanded === 'export' }"
					 @mouseleave="expanded = false">
					<div v-if="submenu === null">
						<button v-if="exportDrivers.wordpress"
								type="button"
								@click.stop.prevent="onExport('wordpress')">
							<span class="icon"><i class="fa fa-wordpress" /></span>
							<span>WordPress</span>
						</button>

						<button v-if="exportDrivers.html"
								type="button"
								@click.stop.prevent="onExport('html')">
							<span class="icon"><i class="fa fa-code" /></span>
							<span>HTML</span>
						</button>

						<button v-if="exportDrivers.html"
								type="button"
								@click.stop.prevent="onExport('word')">
							<span class="icon"><i class="fa fa-file-word-o" /></span>
							<span>Word</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="item-wrapper">
			<component v-if="!! components[element.slug]"
					   :is="components[element.slug]"
					   :generating="generating"
					   @iterate="iterate"
					   @focus="onFocus"
					   v-model="element"
			/>

			<pre v-else>
				{{ element}}
			</pre>
		</div>
	</div>
</template>

<script>
import Editable from '@/app/copilot/components/Editable'

import Qs from "qs";

import { API_URL } from '@/config/env'
import TokenService from '@/services/_app/storage/TokenService'
import CoPilotItemService from '@/services/ai/CoPilotItemService'
import { mapGetters } from 'vuex'

import tones from '@/app/copilot/constants/tones'
import languages from '@/app/copilot/constants/languages'

import LinkedInPostElement from '@/app/copilot/components/elements/social/LinkedInPostElement'
import HeadlineElement from '@/app/copilot/components/elements/text/HeadlineElement'
import BlogPostElement from '@/app/copilot/components/elements/content/BlogPostElement'
import NewsletterElement from '@/app/copilot/components/elements/email/NewsletterElement'
import ImageElement from '@/app/copilot/components/elements/Image/ImageElement'
import FacebookPostElement from '@/app/copilot/components/elements/social/FacebookPostElement'
import GoogleExtendedTextAd from '@/app/copilot/components/elements/ads/GoogleExtendedTextAd'
import ParagraphElement from '@/app/copilot/components/elements/text/ParagraphElement'

import partialParse from 'partial-json-parser'
const XhrStream = require('xhr-stream')

export default {
	props: {
		active: {
			type: Boolean,
			required: false,
			default: false,
		},

		element: {
			type: Object,
			required: true
		},

		item: {
			type: Object,
			required: true
		},

		generating: {
			type: Boolean,
			required: false,
			default: false
		},

		isFirst: {
			type: Boolean,
			required: false,
			default: true
		},

		isLast: {
			type: Boolean,
			required: false,
			default: true
		},

		index: {
			type: Number,
			required: true
		},

		wordpress: {
			type: Object,
			required: false,
			default: null
		}
	},

	data: () => ({
		focused: null,
		submenu: null,
		iterator: 0,
		expanded: false,
		target: null,

		label: {
			show: true,
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			text: null,
			placeholder: null
		},

		components: {
			// Content
			'content.article': BlogPostElement,
			'content.blogPost': BlogPostElement,
			'content.productPage': BlogPostElement,

			// Email
			'email.newsletter': NewsletterElement,

			// Text
			'text.headline': HeadlineElement,
			'text.paragraph': ParagraphElement,

			// Image
			'image.image': ImageElement,

			// Social
			'social.linkedIn': LinkedInPostElement,
			'social.facebook': FacebookPostElement,

			// Ads
			'ads.googleExtendedTextAd': GoogleExtendedTextAd,
		},

			insertPlaceholderCharacter: '♣'
	}),

	watch: {
		active() {
			this.submenu = null
		}
	},

	computed: {
		hasWordPress() {
			return !! this.wordpress && !! this.wordpress.verifiedAt
		},

		showSettings() {
			return this.element.slug !== 'image.image'
		},

		exportDrivers() {
			const slug = this.element.slug

			if (! [
				'content.article',
				'content.blogPost',
				'content.productPage',
				'email.newsletter'
			].includes(slug)) {
				return {}
			}

			return {
				html: true,
				word: true,
				wordpress: this.hasWordPress && this.toolbar.exportWordPress
			}
		},

		showExport() {
			return Object.values(this.exportDrivers).filter(val => !! val).length > 0
		},

		toolbar() {
			const slug = this.element.slug

			return {
				"text": [
					'content.article',
					'content.blogPost',
					'content.productPage',
					'email.newsletter',
					'text.paragraph',
				].includes(slug),

				"format": [
					'content.article',
					'content.blogPost',
					'content.productPage',
					'email.newsletter',
					'text.headline',
					'text.paragraph',
				].includes(slug),

				"align": [
					'content.article',
					'content.blogPost',
					'content.productPage',
					'email.newsletter',
					'text.paragraph',
				].includes(slug),

				"list": [
					'content.article',
					'content.blogPost',
					'content.productPage',
					'email.newsletter',
					'text.paragraph',
				].includes(slug),

				"export": [
					'content.article',
					'content.blogPost',
					'content.productPage',
					'email.newsletter',
				].includes(slug),

				"exportWordPress": [
					'content.article',
					'content.blogPost',
					'content.productPage',
				].includes(slug)
			}
		},

		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		tones() {
			return Object.keys(tones)
				.reduce(
					(carry, key) => ({
						...carry,
						[key]: {
							icon: tones[key].icon,
							title: this.$t(`copilot.tones.${key}`)
						}
					}),
					{}
				)
		},

		languages() {
			return JSON.parse(JSON.stringify(languages))
		},

		formatting() {
			const iterator = this.iterator

			// Selection

			const currentTextStyle = this.currentTextStyle() || {}

			return {
				h1: currentTextStyle.headerLevel == 1,
				h2: currentTextStyle.headerLevel == 2,
				h3: currentTextStyle.headerLevel == 3,

				unorderedList: currentTextStyle.isList && ["disc", "circle"].includes(currentTextStyle.listStyleType),
				orderedList: currentTextStyle.isList && currentTextStyle.listStyleType == "decimal",

				bold: document.queryCommandState('bold'),
				italic: document.queryCommandState('italic'),
				underline: document.queryCommandState('underline'),
			}
		}
	},

	mounted() {
		window.addEventListener('click', this.onClick)

		this.$el.querySelectorAll('[data-label]')
			.forEach(element => {
				element.addEventListener('mouseover', this.showLabel)
				element.addEventListener('mouseleave', this.hideLabel)
			})
	},

	beforeDestroy() {
		window.removeEventListener('click', this.onClick)

		this.$el.querySelectorAll('[data-label]')
			.forEach(element => {
				element.removeEventListener('mouseover', this.showLabel)
				element.removeEventListener('mouseleave', this.hideLabel)
			})
	},

	methods: {
		showLabel($event) {
			const { target } = $event

			const label = target.dataset.label || null

			if (! label) {
				return
			}

			this.$set(this.label, 'placeholder', label)

			this.$nextTick(() => {
				// Calculate width / height

				const placeholderBounds = this.$refs.labelPlaceholder.getBoundingClientRect()
				const bounds = target.getBoundingClientRect()

				this.$set(this.label, 'x', bounds.x + (bounds.width / 2))
				this.$set(this.label, 'y', bounds.y)
				this.$set(this.label, 'width', placeholderBounds.width)
				this.$set(this.label, 'height', placeholderBounds.height)
				this.$set(this.label, 'text', label)
				this.$set(this.label, 'show', true)
			})
		},

		hideLabel($event) {
			this.label.show = false
		},

		onClick($event) {
			let ignore = false
			let match = false

			let target = $event.target
			let previous = null

			while(true) {
				if (target.isContentEditable) {
					match = true
				} else if (match) {
					target = previous
					break
				}

				// Is inline

				if (!! target.classList && target.classList.contains('inline-editor')) {
					ignore = true
					break
				}

				if (! target.parentNode) {
					break
				}

				previous = target
				target = target.parentNode
			}

			if (ignore) {
				return
			}

			this.target = match
				? target
				: null
		},

		onRewrite() {
			if (! this.target) {
				return
			}

			// Focus element

			this.target.focus()

			// Find position, before and after

			if (! this.insertPlaceholders()) {
				return
			}

			// Fire event

			eventHub.$emit('copilot.editable.updated', this.target.dataset.id)

			this.$emit('generating', true)

			// Replace areas

			const texts = this.element.text || {}

			const promises = Object.keys(texts)
				.reduce(
					(carry, key) => {
						const text = texts[key]
						// Does not include placeholder character

						if (! text.includes(this.insertPlaceholderCharacter)) {
							return carry
						}

						carry.push(new Promise((resolve) => {
							const query = Qs.stringify({
								input: text,
								item_id: this.item.id,
								customerId: this.customer.id
							})

							const xhr = new XMLHttpRequest()

							xhr.open('POST', `${API_URL}/co-pilot/actions/rewrite?${query}`, true)
							xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

							const stream = new XhrStream( xhr )

							let result = ' '

							stream.on('data', (response) => {
								if (xhr.status !== 200) {
									resolve()
									return
								}

								let additional = response.toString()
								result = `${result}${additional}`

								const regex = new RegExp(`${this.insertPlaceholderCharacter}(.*)${this.insertPlaceholderCharacter}`, '')

								this.$emit('update', {
									text: {
										[key]: text.replace(regex, result).replace(/  +/g, ' ')
									}
								})
							})

							stream.on('end', () => {
								resolve()
							})

							stream.on('error', () => {
								resolve()
							})
						}))

						return carry
					},
					[]
				)

			// Temporarily remove characters

			this.$nextTick(() => {
				const regex = new RegExp(this.insertPlaceholderCharacter, 'g')

				this.$emit('update', {
					text: Object.keys(texts).reduce(
						(carry, key) => ({
							...carry,
							[key]: texts[key].replace(regex, '')
						}),
						{}
					)
				})

				this.iterate()
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onSummarize() {
			if (! this.target) {
				return
			}

			// Focus element

			this.target.focus()

			// Find position, before and after

			if (! this.insertPlaceholders()) {
				return
			}

			// Fire event

			eventHub.$emit('copilot.editable.updated', this.target.dataset.id)

			this.$emit('generating', true)

			// Replace areas

			const texts = this.element.text || {}

			const promises = Object.keys(texts)
				.reduce(
					(carry, key) => {
						const text = texts[key]
						// Does not include placeholder character

						if (! text.includes(this.insertPlaceholderCharacter)) {
							return carry
						}

						carry.push(new Promise((resolve) => {
							const query = Qs.stringify({
								input: text,
								item_id: this.item.id,
								customerId: this.customer.id
							})

							const xhr = new XMLHttpRequest()

							xhr.open('POST', `${API_URL}/co-pilot/actions/summarize?${query}`, true)
							xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

							const stream = new XhrStream( xhr )

							let result = ' '

							stream.on('data', (response) => {
								if (xhr.status !== 200) {
									resolve()
									return
								}

								let additional = response.toString()
								result = `${result}${additional}`

								const regex = new RegExp(`${this.insertPlaceholderCharacter}(.*)${this.insertPlaceholderCharacter}`, '')

								this.$emit('update', {
									text: {
										[key]: text.replace(regex, result).replace(/  +/g, ' ')
									}
								})
							})

							stream.on('end', () => {
								resolve()
							})

							stream.on('error', () => {
								resolve()
							})
						}))

						return carry
					},
					[]
				)

			// Temporarily remove characters

			this.$nextTick(() => {
				const regex = new RegExp(this.insertPlaceholderCharacter, 'g')

				this.$emit('update', {
					text: Object.keys(texts).reduce(
						(carry, key) => ({
							...carry,
							[key]: texts[key].replace(regex, '')
						}),
						{}
					)
				})

				this.iterate()
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onExtend($event) {
			if (! this.target) {
				return
			}

			// Focus element

			this.target.focus()

			// Find position, before and after

			if (! this.insertPlaceholder()) {
				return
			}

			// Fire event

			eventHub.$emit('copilot.editable.updated', this.target.dataset.id)

			this.$emit('generating', true)

			// Replace areas

			const texts = this.element.text || {}

			const promises = Object.keys(texts)
				.reduce(
					(carry, key) => {
						const text = texts[key]
						// Does not include placeholder character

						if (! text.includes(this.insertPlaceholderCharacter)) {
							return carry
						}

						carry.push(new Promise((resolve) => {
							const query = Qs.stringify({
								input: text,
								item_id: this.item.id,
								customerId: this.customer.id
							})

							const xhr = new XMLHttpRequest()

							xhr.open('POST', `${API_URL}/co-pilot/actions/extend?${query}`, true)
							xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

							const stream = new XhrStream( xhr )

							let result = ' '

							stream.on('data', (response) => {
								if (xhr.status !== 200) {
									resolve()
									return
								}

								let additional = response.toString()
								result = `${result}${additional}`

								this.$emit('update', {
									text: {
										[key]: text.replace(this.insertPlaceholderCharacter, result).replace(/  +/g, ' ')
									}
								})
							})

							stream.on('end', () => {
								resolve()
							})

							stream.on('error', () => {
								resolve()
							})
						}))

						return carry
					},
					[]
				)

			// Temporarily remove characters

			this.$nextTick(() => {
				const regex = new RegExp(this.insertPlaceholderCharacter, 'g')

				this.$emit('update', {
					text: Object.keys(texts).reduce(
						(carry, key) => ({
							...carry,
							[key]: texts[key].replace(regex, '')
						}),
						{}
					)
				})

				this.iterate()
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		insertPlaceholder() {
			if (! this.target) {
				return false
			}

			const sel = window.getSelection();

			if (sel.rangeCount) {
				const range = sel.getRangeAt(0);

				range.deleteContents();

				const textNode = document.createTextNode(this.insertPlaceholderCharacter);

				range.insertNode(textNode);

				range.setStartAfter(textNode);
				range.setEndAfter(textNode);
				sel.removeAllRanges();
				sel.addRange(range);

				return true
			}

			return false
		},

		insertPlaceholders() {
			if (! this.target) {
				return false
			}

			const sel = window.getSelection();

			if (sel.rangeCount && ! sel.isCollapsed) {
				const range = sel.getRangeAt(0);
				const startNode = document.createTextNode(this.insertPlaceholderCharacter)
				const endNode = document.createTextNode(this.insertPlaceholderCharacter)

				range.insertNode(startNode)
				range.collapse(false)
				range.insertNode(endNode)

				const newRange = document.createRange()
				newRange.setStartAfter(startNode)
				newRange.setEndBefore(endNode)
				sel.removeAllRanges()
				sel.addRange(newRange)

				return true
			}

			return false
		},

		moveUp() {
			this.$emit('moveUp')
		},
		moveDown() {
			this.$emit('moveDown')
		},

		clearFormatting() {
			this.executeCommandRaw('removeFormat')
			this.executeCommandRaw('formatBlock', false, '<div>')
		},

		currentTextStyle() {
			let style = false;
			const sel = window.getSelection()

			if(sel.focusNode) {
				const element = sel.focusNode.tagName ? sel.focusNode : sel.focusNode.parentElement;
				if(element && element.isContentEditable) {
					style = window.getComputedStyle(element);

					// compute additional properties
					style.textDecorationStack = []; // array of text-decoration strings from parent elements
					style.headerLevel = 0;
					style.isList = false;
					let parent = element;
					while(parent){
						const parent_style = window.getComputedStyle(parent);
						// stack CSS text-decoration as it is not overridden by children
						style.textDecorationStack.push(parent_style.textDecoration);
						// check if one parent is a list-item
						if(parent_style.display == "list-item") style.isList = true;
						// get first header level, if any
						if(!style.headerLevel){
							for(let i = 1; i <= 6; i++){
								if(parent.tagName.toUpperCase() == "H"+i) {
									style.headerLevel = i;
									break;
								}
							}
						}
						parent = parent.parentElement;
					}
				}
			}

			return style
		},

		iterate() {
			setTimeout(() => {
				this.iterator++
			}, 50)
		},

		executeCommand($event, command) {
			$event.preventDefault()
			$event.stopPropagation()
			$event.stopImmediatePropagation()

			document.execCommand(command, false)

			$event.target.focus()

			this.iterate()
		},

		executeCommandRaw(...args) {
			document.execCommand(...args)

			this.iterate()
		},

		onFocus() {
			this.$emit('focus')
		},

		onBlur() {
			this.$emit('blur')
		},

		onChangePrompt() {
			this.$emit('changePrompt')
		},

		onGenerate() {
			if (this.generating) {
				return
			}

			const element = this.element

			this.$emit('generating', true)

			const promise = new Promise((resolve) => {
				const query = Qs.stringify({
					...element,
					item_id: this.item.id,
					customerId: this.customer.id
				})

				const xhr = new XMLHttpRequest()

				xhr.open('POST', `${API_URL}/co-pilot/actions/generate?${query}`, true)
				xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

				const stream = new XhrStream( xhr )

				let text = ''

				stream.on('data', (response) => {
					if (xhr.status !== 200) {
						this.$emit('generating', false)
						return
					}

					let additional = response.toString()
					additional = additional.replace(/(\r\n|\n|\r)/gm, "")

					text = `${text}${additional}`

					let parsed = null
					let placeholder = null

					try {
						placeholder = partialParse(`${text || '{}'}"}`)

						if (!! placeholder) {
							parsed = placeholder
						}
					} catch (throwable) {
					}

					if (parsed === null) {
						try {
							placeholder = partialParse(text || '{}')

							if (!! placeholder) {
								parsed = placeholder
							}
						} catch (throwable) {
						}
					}

					if (parsed === null) {
						return
					}

					const keys = Object.keys(parsed)

					keys.forEach(key => {
						this.onUpdateTextRaw(key, parsed[key])
					})
				})

				stream.on('end', () => {
					resolve()
				})

				stream.on('error', () => {
					resolve()
				})
			})

			promise.finally(() => {
				this.$emit('generating', false)
			})
		},

		onUpdateTextRaw(key, content, last = false) {
			// Check if response is an error response

			const errorText = `{"error"`

			const length = content.length > errorText.length
				? errorText.length
				: content.length

			if (content.substring(0, length) === errorText.substring(0, length)) {
				return
			}

			this.$emit('update', {
				text: {
					[key]: last
						? content
						: this.parsedContent(content)
				}
			})
		},

		parsedContent(content) {
			if (content.endsWith('</')) {
				content = content.substring(0, -2)
			}

			if (content.endsWith('<')) {
				content = content.substring(0, -1)
			}

			return content
		},

		onTone(tone) {
			this.expanded = false

			this.$emit('update', {
				prompt: {
					'tone': tone
				}
			})

			this.$emit('generating', true)

			this.submenu = null

			const promises = Object.keys(this.element.text || {}).map(key => {
				return new Promise((resolve) => {
					if (! this.element.text[key]) {
						resolve()
						return
					}

					const query = Qs.stringify({
						input: this.element.text[key],
						tone,
						emojis: this.element.prompt.emojis ? '1' : '0'
						// customerId: this.customer.id
					})

					const xhr = new XMLHttpRequest()

					xhr.open('POST', `${API_URL}/co-pilot/actions/tone?${query}`, true)
					xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

					const stream = new XhrStream( xhr )

					let text = ''

					stream.on('data', (response) => {
						text = `${text}${response.toString()}`

						this.onUpdateTextRaw(key, text)
					})

					stream.on('end', () => {
						this.onUpdateTextRaw(key, text, true)

						resolve()
					})

					stream.on('error', () => {
						resolve()
					})
				})
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onTranslate(language) {
			this.expanded = false

			this.$emit('generating', true)

			this.submenu = null

			const promises = Object.keys(this.element.text || {}).map(key => {
				return new Promise((resolve) => {
					if (! this.element.text[key]) {
						resolve()
						return
					}

					const query = Qs.stringify({
						input: this.element.text[key],
						language,
						// customerId: this.customer.id
					})

					const xhr = new XMLHttpRequest()

					xhr.open('POST', `${API_URL}/co-pilot/actions/translate?${query}`, true)
					xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

					const stream = new XhrStream( xhr )

					let text = ''

					stream.on('data', (response) => {
						text = `${text}${response.toString()}`

						this.onUpdateTextRaw(key, text)
					})

					stream.on('end', () => {
						this.onUpdateTextRaw(key, text, true)

						resolve()
					})

					stream.on('error', () => {
						resolve()
					})
				})
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onRephrase() {
			this.expanded = false

			this.$emit('generating', true)

			const promises = Object.keys(this.element.text || {}).map(key => {
				return new Promise((resolve) => {
					if (! this.element.text[key]) {
						resolve()
						return
					}

					const query = Qs.stringify({
						input: this.element.text[key],
						// customerId: this.customer.id
					})

					const xhr = new XMLHttpRequest()

					xhr.open('POST', `${API_URL}/co-pilot/actions/rephrase?${query}`, true)
					xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

					const stream = new XhrStream( xhr )

					let text = ''

					stream.on('data', (response) => {
						text = `${text}${response.toString()}`

						this.onUpdateTextRaw(key, text)
					})

					stream.on('end', () => {
						this.onUpdateTextRaw(key, text, true)

						resolve()
					})

					stream.on('error', () => {
						resolve()
					})
				})
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onFixGrammar() {
			this.expanded = false

			this.$emit('generating', true)

			const promises = Object.keys(this.element.text || {}).map(key => {
				return new Promise((resolve) => {
					if (! this.element.text[key]) {
						resolve()
						return
					}

					const query = Qs.stringify({
						input: this.element.text[key],
						// customerId: this.customer.id
					})

					const xhr = new XMLHttpRequest()

					xhr.open('POST', `${API_URL}/co-pilot/actions/grammar?${query}`, true)
					xhr.setRequestHeader('Authorization', `Bearer ${TokenService.getToken()}`)

					const stream = new XhrStream( xhr )

					let text = ''

					stream.on('data', (response) => {
						text = `${text}${response.toString()}`

						this.onUpdateTextRaw(key, text)
					})

					stream.on('end', () => {
						this.onUpdateTextRaw(key, text, true)

						resolve()
					})

					stream.on('error', () => {
						resolve()
					})
				})
			})

			Promise.all(promises)
				.finally(() => {
					this.$emit('generating', false)
				})
		},

		onRemove() {
			this.$emit('remove')
		},

		onExport(type) {
			switch (type) {
				case 'wordpress':
					if (! this.hasWordPress) {
						return
					}

					const url = `https://${this.wordpress.domain}/wp-admin/options-general.php?page=opn-admin&item_id=${this.item.id}&index=${this.index}`
					window.open(url, '_blank')

					return

				case 'html':
				case 'word':
					this.$emit('exporting', true)

					CoPilotItemService.exportItem(
						{
							type,
							item_id: this.item.id,
							index: this.index
						},
						response => {
							// create file link in browser's memory
							const href = URL.createObjectURL(response.data);

							// create "a" HTML element with href to file & click
							const link = document.createElement('a');
							link.href = href;
							link.setAttribute('download', response.headers['x-file-name']); //or any other extension
							document.body.appendChild(link);
							link.click();

							// clean up "a" element & remove ObjectURL
							document.body.removeChild(link);
							URL.revokeObjectURL(href);

							this.$emit('exporting', false)
						},
						() => {
							this.$emit('exporting', false)
						},
						{
							responseType: 'blob'
						}
					)
					return
			}
		}
	},

	components: {Editable}
}
</script>

<style lang="scss" scoped>
.element {
	position: relative;

	&.generating {
		pointer-events: none !important;

		* {
			pointer-events: none !important;
		}
	}

	::v-deep h1 {
		color: #000;
		font-size: 45px;
		font-weight: 600;
		margin: 20px 0 15px 0;
	}

	::v-deep h2 {
		color: #000;
		font-size: 36px;
		font-weight: 500;
		margin: 20px 0 15px 0;
	}

	::v-deep h3 {
		color: #000;
		font-size: 22px;
		font-weight: 400;
		margin: 20px 0 15px 0;
	}

	::v-deep *[contenteditable="true"] {
		outline: 0;
	}

	.item-wrapper {
		border: 1px solid transparent;
		padding: 20px;
		border-radius: 16px;

		word-break: break-word;
	}

	&:hover {
		.item-wrapper {
			border-color: #eaeaea;
		}
	}

	&.active {
		> div.inline-editor {
			opacity: 1;

			> div.inner {
				pointer-events: all;
			}

			&.generating {
				cursor: not-allowed;
				opacity: .5;
				pointer-events: none;

				div.button-submenu {
					display: none !important;
				}

				* {
					pointer-events: none !important;
				}
			}
		}
	}

	> div.inline-editor {
		pointer-events: none;
		opacity: 0;
		transition: opacity .2s;
		z-index: 97;

		position: sticky;
		top: 20px;
		left: 0;

		display: flex;
		height: 0px;

		.inner-label,
		.inner-label-placeholder {
			position: fixed;
			display: block;
			padding: 3px 6px;
			font-size: 11px;
			height: 20px;
			font-weight: 500;
		}

		.inner-label-placeholder {
			position: absolute;
			top: -1000px;
			left: -1000px;
		}

		.inner-label {
			color: #fff;
			background-color: #000;
			transform: translateX(-50%) translateY(-100%);
			margin-top: -10px;
			border-radius: 4px;
			z-index: 9999;

			transition: all .25s;

			&:after {
				position: absolute;
				top: 100%;
				left: 50%;
				width: 6px;
				height: 6px;
				background-color: #000;
				transform: translateY(-50%) translateX(-50%) rotate(45deg);
				content: '';
			}
		}

		.dash {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) rotate(45deg);
			height: 50%;
			width: 2px;
			background-color: #333;
			border-left: 1px solid #fff;
		}

		> div.inner {
			position: relative;

			max-width: calc(100% - 40px);

			padding: 0 20px;

			> div.scroller {
				height: 45px;
				border-radius: 45px;
				padding: 0 12px;
				display: flex;
				align-items: center;
				background-color: #fff;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

				transform: translateY(-75%);

				overflow-x: scroll;
				scrollbar-width: none;

				&::-webkit-scrollbar {
					display: none;
				}

				> div.button-group {
					display: flex;
					align-items: center;

					padding: 0 6px;
					border-right: 1px solid #eee;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						border-right: 0;
						padding-right: 0;
					}
				}
			}

			.button {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 6px;
				height: 33px;
				min-width: 33px;
				margin: 0;
				border-radius: 6px;
				background-color: #fff;
				border: 0;

				&.active,
				&.clickable:hover {
					border-radius: 6px;
					background-color: #eee;
				}

				.label {
					display: none;
					position: fixed;
					color: #fff;
					margin-top: -50px;
					background-color: #000;
				}
			}

			> div.button-submenu {
				position: absolute;
				top: 100%;
				left: 100%;
				transform: translateX(-90%);
				padding-top: 15px;

				transition: opacity .25s;
				pointer-events: none;
				opacity: 0;

				min-height: 200px;

				&.expanded {
					pointer-events: all;
					opacity: 1;
				}

				> div {
					width: 200px;

					overflow: hidden;

					border-radius: 12px;
					background-color: #fff;
					box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

					> * {
						cursor: pointer;

						user-select: none;

						display: flex;
						align-items: center;
						gap: 12px;

						height: 42px;

						width: 100%;
						border: 0;
						background-color: #fff;

						color: #464646;
						font-weight: 500;
						font-size: 13px;
						padding: 10px 15px;

						.emoji {
							font-size: 16px;
						}

						&:hover {
							color: #000;
							background-color: #eee;
						}
					}
				}
			}
		}
	}
}


@media screen and (max-width: 992px) {
	.element {
		> div.inline-editor {
			> div.inner {
				> div.button-submenu {
					left: 20px;
					transform: none;
				}
			}
		}
	}
}
</style>
