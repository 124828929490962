<template>
	<div v-else-if="element.slug === 'social.linkedIn'"
		 class="linkedin-post">
		<div class="user">
			<div class="image">
				<img src="/images/opn/logo_white.svg" />
			</div>

			<div class="meta">
				<div class="name">OP'N Co-pilot</div>
				<div class="subline">AI enthusiast and automated co-pilot</div>
				<div class="time">1d</div>
			</div>
		</div>

		<div class="content">
			<editable :disabled="generating"
					  @iterate="iterate"
					  @focus="onFocus"
					  v-model="internalElement.text.post"
			/>
		</div>

		<div class="image">
			<div class="inner">
				<ai-image width="100%"
						  height="100%"
						  v-model="internalElement.images.image"
				/>
			</div>
		</div>

		<div class="likes">
			<div class="count">
				<div class="images">
					<img src="https://static.licdn.com/aero-v1/sc/h/8ekq8gho1ruaf8i7f86vd1ftt" />
					<img src="https://static.licdn.com/aero-v1/sc/h/b1dl5jk88euc7e9ri50xy5qo8" />
					<img src="https://static.licdn.com/aero-v1/sc/h/lhxmwiwoag9qepsh4nc28zus" />
				</div>
				<span>117</span>
			</div>
			<div class="info">13 comments <span>•</span> 14 reposts</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.linkedin-post {
	max-width: 555px;
	margin: 0 auto;

	box-shadow: rgba(140, 140, 140, 0.2) 0px 0px 0px 1px;
	border-radius: 8px;

	.user {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 10px 15px 0 15px;

		.image {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			border-radius: 50%;
			overflow: hidden;
			background-color: #ee95d3;

			img {
				width: 60%;
				height: 60%;
				object-fit: cover;
			}
		}

		.meta {
			.name {
				color: #000;
				font-weight: 600;
			}

			.subline,
			.time {
				color: rgba(0, 0, 0, 0.6);
				font-size: 12px;
				font-weight: 400;
			}
		}
	}

	> .content {
		padding: 10px 15px;
	}

	> .image {
		position: relative;
		width: 100%;
		padding-bottom: 56%;

		> div.inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	> div.likes {
		display: flex;
		align-items: center;
		justify-content: space-between;

		padding: 10px 15px;

		color: rgba(0, 0, 0, 0.6);
		font-size: 12px;
		font-weight: 400;

		div.count {
			display: flex;
			align-items: center;
			gap: 4px;

			div.images {
				> img {
					border: 1px solid #fff;
					border-radius: 50%;

					&:first-child {
						margin-left: 0;
					}

					margin-left: -5px;
				}
			}
		}
	}
}
</style>

<script>
import Editable from '@/app/copilot/components/Editable'
import AiImage from "@/app/copilot/components/AiImage";

export default {
	props: {
		value: {},

		generating: {
			type: Boolean,
			required: false,
			default: false
		}
	},

	computed: {
		internalElement: {
			get() {
				return this.value
			},

			set(value) {
				this.$emit('input', value)
			}
		}
	},

	methods: {
		iterate() {
			this.$emit('iterate')
		},

		onFocus() {
			this.$emit('focus')
		}
	},

	components: {
		AiImage,
		Editable
	}
}
</script>
