'<template>
	<card :headline="$t('copilot.businessContext.headline')"
		  :description="$t('copilot.businessContext.description')">
		<div class="inner">
			<div class="actions">
				<button type="button"
						@click="onRefresh"
						:disabled="! canClick"
						class="dark">
					<i v-if="! refreshing" class="far fa-download fa-fw" />
					<i v-else class="far fa-spinner-third fa-spin fa-fw" />
					<span>{{ $t('copilot.businessContext.actions.refresh') }}</span>
				</button>

				<button v-if="! editing"
						type="button"
						@click="onEdit"
						:disabled="! canClick"
						class="secondary">
					<i class="far fa-edit fa-fw" />
					<span v-if="hasDescription">{{ $t('copilot.businessContext.actions.edit') }}</span>
					<span v-else>{{ $t('copilot.businessContext.actions.create') }}</span>
				</button>

				<button v-if="editing"
						type="button"
						@click="onSave"
						:disabled="saving"
						class="secondary">
					<i v-if="! saving" class="far fa-download fa-fw" />
					<i v-else class="far fa-spinner-third fa-spin fa-fw" />
					<span>{{ $t('copilot.businessContext.actions.save') }}</span>
				</button>

				<button v-if="editing"
						type="button"
						@click="editing = false"
						:disabled="saving"
						class="danger">
					<i class="far fa-x fa-fw" />
					<span>{{ $t('copilot.businessContext.actions.cancel') }}</span>
				</button>

				<button v-if="hasDescription && ! editing"
						type="button"
						@click="onRemove"
						:disabled="! canClick"
						class="danger">
					<i v-if="! removing" class="far fa-trash-o fa-fw" />
					<i v-else class="far fa-spinner-third fa-spin fa-fw" />
					<span>{{ $t('copilot.businessContext.actions.remove') }}</span>
				</button>
			</div>

			<template v-if="loaded">
				<template v-if="! editing">
					<div v-if="hasDescription" class="description" v-text="description" />

					<div v-else class="no-description">
						<div class="top">
							<div class="icon"><i class="far fa-exclamation fa-fw" /></div>

							<div class="text">
								<div class="headline">{{ $t('copilot.businessContext.noDescription.headline') }}</div>
								<div class="description">{{ $t('copilot.businessContext.noDescription.description') }}</div>
							</div>
						</div>
					</div>
				</template>

				<textarea v-else
						  v-model="placeholder"
						  class="form-control field"
				/>
			</template>

			<div v-if="! loaded" class="loader">
				<div class="icon"><i class="far fa-spinner-third fa-spin" /></div>
				<div class="text">{{ $t('copilot.businessContext.loading') }}</div>
			</div>
		</div>
	</card>
</template>

<script>
import BusinessContextService from '@/services/ai/BusinessContextService'
import {mapGetters} from "vuex";

export default {
	data: () => ({
		refreshing: false,
		editing: false,
		removing: false,
		saving: false,
		loaded: false,

		description: '',
		placeholder: ''
	}),

	computed: {
		...mapGetters('customer', {
			customer: 'getCustomer'
		}),

		canClick() {
			return ! this.refreshing && ! this.removing && ! this.saving && this.loaded
		},

		hasDescription() {
			return String(this.description || '').length > 0
		}
	},

	mounted() {
		this.onLoad()
	},

	methods: {
		onLoad() {
			this.loaded = false

			BusinessContextService.get({}, ({ data }) => {
				this.loaded = true
				this.description = (data || {}).description || ''
			}, () => {
				this.loaded = true
			})
		},

		onEdit() {
			this.placeholder = this.description
			this.editing = true
		},

		onSave() {
			this.saving = true

			BusinessContextService.update({
				description: this.placeholder
			}, ({ data }) => {
				this.description = (data || {}).description || ''
				this.placeholder = (data || {}).description || ''

				this.editing = false
				this.saving = false
			}, () => {
				this.saving = false
			})
		},

		onRemove() {
			this.editing = false
			this.removing = true

			BusinessContextService.remove({}, ({ data }) => {
				this.description = ''
				this.removing = false
			}, () => {
				this.removing = false
			})
		},

		onRefresh() {
			this.refreshing = true

			BusinessContextService.refresh({}, ({ data }) => {
				this.description = (data || {}).description || ''
				this.placeholder = (data || {}).description || ''

				this.refreshing = false
			}, () => {
				this.refreshing = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.inner {
	> .actions {
		display: flex;
		align-items: center;
		gap: 10px;
		margin: 0 0 25px 0;

		button {
			display: flex;
			align-items: center;
			gap: 8px;

			color: #fff !important;
			padding: 12px 16px !important;
			border-radius: 6px !important;
			font-size: 13px !important;
			letter-spacing: -.2px !important;
			font-weight: 600 !important;
			border: 0 !important;
			background-color: #000 !important;

			transition: opacity .25s ease-in-out;
			will-change: opacity;

			&.secondary {
				background-color: #132e56 !important;
			}

			&.danger {
				background-color: #c90808 !important;
			}

			&:disabled {
				cursor: not-allowed;
				opacity: .5;
			}
		}
	}

	> .description {
		border-left: 4px solid #000;
		padding: 10px 20px;
	}

	.field {
		min-height: 100px;
	}

	> div.no-description {
		> div.top {
			display: flex;
			align-items: flex-start;
			gap: 10px;

			> div.icon {
				width: 45px;
				height: 45px;
				min-width: 45px;
				min-height: 45px;
				border-radius: 6px;

				display: flex;
				align-items: center;
				justify-content: center;

				font-size: 20px;

				color: #000;
				background-color: #eee;
			}

			> div.text {
				display: flex;
				flex-direction: column;

				> div.headline {
					color: #000;
					font-weight: 500;
					font-size: 16px;
				}

				> div.description {
					color: #484848;
					font-weight: 400;
					font-size: 13px;
				}
			}
		}
	}

	> div.loader {
		display: flex;
		align-items: center;
		gap: 10px;

		> div.icon {
			width: 45px;
			height: 45px;
			min-width: 45px;
			min-height: 45px;
			border-radius: 6px;

			display: flex;
			align-items: center;
			justify-content: center;

			font-size: 20px;

			color: #000;
			background-color: #eee;
		}

		> div.text {
			color: #000;
			font-weight: 500;
			font-size: 16px;
		}
	}
}
</style>
